import React, { useRef, useState, useEffect } from "react";
import { filterEvents, shareUrl } from "../helper/Data";
import VenueCard from "../components/venueCard";
import CategoryMap from "../components/categoryMap";
import pageTransition from "../components/pageTransition";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import StartSettings from "../components/startSettings";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";
import LoadingScreen from "../components/loadingScreen";
import { modalController } from "@ionic/core";

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface ConcertProps {
    categoriesLoaded: boolean;
    eventsLoaded: boolean;
}

interface Category {
    id: number;
    parent_id: number;
    slug: string;
    name_de: string;
    description_de: string;
    name_en: string;
    description_en: string;
    search_str: string;
    venue_count: number;
}

const Events: React.FC<ConcertProps> = ({ categoriesLoaded, eventsLoaded }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Use `useLocation` to extract category and parent
    const location = useLocation();
    const internalOrigin = location.state?.fromInternal || false;

    const categorySlug = 'live-musik';

    const { trackPageView } = useMatomo();

    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));
    const [language] = useState(localStorage.getItem('i18nextLng') || 'en');

    // 1. venues of category
    const [events, setEvents] = useState<any[]>([])
    const [eventsByDay, setEventsByDay] = useState<any[]>([])

    // 2. mapped venues for the marker
    const [eventsMarker, setEventsMarker] = useState([]);

    const [category, setCategory] = useState<Category | null>(null);

    ////// Slider ///////

    const sliderRef = useRef<Slider>(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const [sliderSettings, setSliderSettings] = useState({
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 3,
        autoplay: false,
        arrows: false,
        swipe: false,
    });

    const changeSlide = (index: number) => {
        if (sliderRef.current) {
            setTimeout(() => {
                sliderRef.current!.slickGoTo(index);
            }, 100); // Delay by 100ms to avoid race condition
        }
    };

    //slider segment

    useEffect(() => {
        const handleSegmentChange = (event: Event) => {
            const customEvent = event as CustomEvent;
            const selectedSlide = customEvent.detail.value;
            // debugger
            if (selectedSlide == 1) {
                // @ts-ignore
                document.getElementById('header-category').className = 'cat-header transparent ion-padding';
            } else {
                // @ts-ignore
                document.getElementById('header-category').className = 'cat-header ion-padding';
            }
            changeSlide(selectedSlide);
        };
    
        const observer = new MutationObserver(() => {
            const segmentListMap = document.getElementById('segmentListMap');
            if (segmentListMap) {
                segmentListMap.addEventListener('ionChange', handleSegmentChange as EventListener);
    
                // Once the element is found and the event listener is added, disconnect the observer
                observer.disconnect();
            }
        });
    
        // Start observing the DOM for changes
        observer.observe(document.body, { childList: true, subtree: true });
    
        // Cleanup: remove the event listener and disconnect the observer
        return () => {
            const segmentListMap = document.getElementById('segmentListMap');
            segmentListMap?.removeEventListener('ionChange', handleSegmentChange as EventListener);
            observer.disconnect();
        };
    }, []);


    ////// Modal Definitions & Functions ///////

    // Description Modal

    const descriptionModalRef = useRef<HTMLIonModalElement | null>(null);

    const openDescriptionModal = async () => {
        const descriptionText = language === 'de' ? category?.description_de : category?.description_en;

        const modal = await modalController.create({
            component: 'div',
            mode: 'ios',
            componentProps: {
                innerHTML: `
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>${t('description')}</ion-title>
                            <ion-buttons slot="end">
                                <ion-button id="closeModalButton">${t('close')}</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content class="ion-padding">
                        <div class="modal-description-text">
                            ${descriptionText || ''}
                        </div>
                    </ion-content>
                `,
            },
            cssClass: 'filter-modal sg-modal', // Custom class for styling the modal
            breakpoints: [0, 0.25, 0.5, 0.75],
            initialBreakpoint: 0.5, // Start the modal at 25% of the screen height
        });
        descriptionModalRef.current = modal; // Store the modal instance
        await modal.present();

        // Attach the close functionality to the close button
        const closeButton = document.getElementById('closeModalButton');
        if (closeButton) {
            closeButton.addEventListener('click', () => modal.dismiss());
        }
    };

    useEffect(() => {
        return () => {
            if (descriptionModalRef.current) {
                descriptionModalRef.current.dismiss().then(() => {
                    descriptionModalRef.current = null;
                });
            }
        };
    }, []);

    //map marker
    const [selectedMarkerID, setSelectedMarkerID] = useState<number>(-1);

    const handleMarkerSelected = (index: number | null) => {
        if (index !== null) {
            setSelectedMarkerID(index);
            //console.log(index);
        }
    };

    useEffect(() => {
        if (!categoriesLoaded || !eventsLoaded) return;
        trackPageView({
            href: window.location.href, // URL der aktuellen Seite
          });


        // Get Category
        const categories: any = localStorage.getItem('categories');
        if (categories) {
            let currentCategoryId = -1;
            // @ts-ignore
            JSON.parse(categories).forEach((item: any) => {
                if (item.slug === categorySlug) {
                    setCategory(item);
                    currentCategoryId = item.id;
                }
            })

            if (currentCategoryId < 0) {
                navigate('/notfound');
            }


            // Get events
            // @ts-ignore
            let tempEvents: any = JSON.parse(localStorage.getItem('events'));
            tempEvents = filterEvents(tempEvents, [currentCategoryId]);
            setEvents(tempEvents);

            type Event = {
                id: number;
                type: string;
                venue_title: string;
                venue_slug: string;
                restaurant__gps_lon: string;
                restaurant__gps_lat: string;
                category: number[];
                event_link: string | null;
                description_1: string;
                description_2: string;
                dow: string;
                dow_till: string;
                date: string | null;
            };
            
            // @ts-ignore           
            function parseEventDate(dateStr: string): Date {
                const parts = dateStr.split('.');
                return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
            }
            
            // @ts-ignore    
            function organizeEventsByDate(events: Event[]): Event[][] {
                // Initialize an array for the next 7 days
                const eventsByDate: Event[][] = [[], [], [], [], [], [], []];
              
                const startDate = new Date();
                startDate.setHours(0, 0, 0, 0);
                // Calculate the end date (6 days into the future)
                const endDate = new Date(startDate);
                endDate.setDate(startDate.getDate() + 6);
                endDate.setHours(23, 59, 59, 999);

                // Get the day of the week for the startDate (1 = Monday, 2 = Tuesday, etc.)
                const startDayOfWeek = (startDate.getDay() + 6) % 7; // Adjusting so 0 = Monday
              
                events.forEach(event => {
                  if (event.type === 'special' && event.date) {
                    const eventDate = parseEventDate(event.date);
                    const dayIndex = Math.floor((eventDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
                    if (dayIndex >= 0 && dayIndex <= 6) { 
                        eventsByDate[dayIndex].push(event);
                    }
                  } else if (event.type === 'weekly') {
                    let startDay = parseInt(event.dow) - 1; // Adjusting for array index (0-6)
                    let endDay = event.dow_till ? parseInt(event.dow_till) - 1 : startDay;

                    // Adjust the days based on the startDayOfWeek
                    startDay = (startDay + 7 - startDayOfWeek) % 7;
                    endDay = (endDay + 7 - startDayOfWeek) % 7;
              
                    for (let day = startDay; day <= endDay; day++) {
                      if (day >= 0 && day <= 6) { 
                        eventsByDate[day].push(event);
                        }
                    }
                  }
                });
              
                return eventsByDate;
              }
              
              // Usage

              const eventsOrganizedByDate = organizeEventsByDate(tempEvents);
              setEventsByDay(eventsOrganizedByDate);

            // @ts-ignore
            const newmarkers = tempEvents.map(({ id, venue_title, restaurant__gps_lat, restaurant__gps_lon }) => ({
                id,
                title: venue_title, // Changed field name from venue_title to title
                isOpen: true, // Set isOpen to always be true
                restaurant__gps_lat,
                restaurant__gps_lon,
            }));

            // @ts-ignore   
            newmarkers.sort((a, b) => b.restaurant__gps_lat - a.restaurant__gps_lat);
            // @ts-ignore
            setEventsMarker(newmarkers);

        }
    }, [location, categoriesLoaded, eventsLoaded]);


    function getEventById(id: number, events: any) {
        const foundEvent = events.find((event: { id: number; }) => event.id === id);
        return foundEvent || null;
    }
    
    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
        categoriesLoaded && eventsLoaded ? (
            <ion-page class="sg-body">
                <div className="page-container">
                <MetaHelmet 
                    title= "Events in Graz"
                    desc= {language === 'de' ? category?.description_de : category?.description_en}
                />
                <ion-header class="ion-padding" id="header-category" mode="ios">
                    <div className="header-container top-margin">
                        {internalOrigin && <button className="arrow-back" style={{ backgroundImage: `url("/icons/back_arrow_white.svg")` }} onClick={() => navigate(-1)}></button>}
                        <div className="header-container__title">
                            <h1 className="start-title left">
                                Events
                            </h1>
                            <span className="share-icon info" onClick={openDescriptionModal}></span>
                        </div>
                        <span onClick={() => shareUrl()} className="share-icon"></span>
                    </div>
                    <div className="filter-map-container">
                    <ion-segment class="segment" id="segmentListMap" value={currentSlide}>
                            <ion-segment-button class="segment-button" value={0}>
                                <label>{t('list')}</label>
                            </ion-segment-button>
                            <ion-segment-button class="segment-button" value={1}>
                                <label>{t('map')}</label>
                            </ion-segment-button>
                        </ion-segment>
                    </div>
                </ion-header>
                    <div className="slider-container">
                        <Slider ref={sliderRef} {...sliderSettings}>
                            <div className="slide">
                                <span className="sortedBy event">
                                </span>
                                <div className="venue-list event">
                                    {eventsByDay.map((dailyEvents, index) => {
                                    // Calculate the date for the current index
                                    const currentDate = new Date();
                                    currentDate.setDate(currentDate.getDate() + index);
                                    const dateString = currentDate.toLocaleDateString(language+'-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
                                    if (dailyEvents.length > 0) {
                                    return (
                                        <div key={index}>
                                        <h2>{dateString}</h2> {/* Displaying the weekday and date */}
                                        {dailyEvents.map((event: { id: React.Key | null | undefined; venue_slug: string; venue_title: string; description_1: string; description_2: string; event_link: string}) => (
                                                <VenueCard
                                                    key={event.id}
                                                    slug={event.venue_slug}
                                                    name={event.venue_title}
                                                    status={event.description_1}
                                                    additionalInformation={event.description_2}
                                                    eventLink={event.event_link}
                                                    menuLink=""
                                                    cssClass=""
                                                />
                                            ))}
                                        </div>
                                    );
                                    }
                                    return(
                                        <div key={index}>
                                        <h2>{dateString}</h2>
                                        </div>
                                    ); // Don't render anything if there are no events for the day
                                    })}
                                </div>
                            </div>
                            <div className="slide">
                                <CategoryMap markers={eventsMarker} onMarkerSelected={handleMarkerSelected}></CategoryMap>
                                {(selectedMarkerID !== -1) &&
                                <Link state={{ fromInternal: true }} className="venue-list__item map" to={`/betriebe/${getEventById(selectedMarkerID, events)?.venue_slug}`}>
                                    <h3 className="venue-list__item-name">{getEventById(selectedMarkerID, events)?.venue_title}</h3>
                                    <p className="venue-list__item-hours">{getEventById(selectedMarkerID, events)?.description_1}</p>
                                    <p className="venue-list__item-hours">{getEventById(selectedMarkerID, events)?.description_2}</p>
                                </Link>
                                }
                            </div>
                        </Slider>
                    </div>
                    </div>
            </ion-page>
            ) : (
                <ion-page class="sg-body">
                    <LoadingScreen />
              </ion-page>
            )
        )
    )
}

export default pageTransition(Events);