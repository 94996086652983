import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import StartSettings from "../components/startSettings";
import { getCategoryBySlug, getParentSlugOfId, shareUrl } from "../helper/Data";
import pageTransition from "../components/pageTransition";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";
import LoadingScreen from "../components/loadingScreen";

interface PostProps {
    newsLoaded: boolean;
}

// Interface für den Post
interface Post {
    id: number;
    slug: string;
    title: string;
    content: string;
    featured_media: string;
    thumbnail: string;
    publish_date: string;
    links: {
        categories: Array<CategoryLink>;  // Define the type of category links
        venues: Array<VenueLink>;         // Define the type of venue links
    };
}

interface CategoryLink {
    'post_link-category': string;
}

interface VenueLink {
    'post_link-venue': {
        title: string;
    };
}

const Post: React.FC<PostProps> = ({ newsLoaded }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Use `useLocation` to extract venue
    const location = useLocation();
    const internalOrigin = location.state?.fromInternal || false;
    const path = location.pathname.substring(1);
    const postSlug = path.split('/')[1];

    const { trackPageView } = useMatomo();

    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));
    const [language] = useState(localStorage.getItem('i18nextLng') || 'en');

    const [post, setPost] = useState<Post | null>(null); // Verwende das Post-Interface

    function getSinglePost(posts: Post[], slug: string): Post | undefined {
        let result = posts.filter((item: Post) => item.slug === slug);
        return result[0];
    }

    const getSlugOfCategory = (categoryUrl: any) => {
        const url = String(categoryUrl['post_link-category']);

        const slug = url.split('/').filter(Boolean).pop();

        if(typeof slug === 'string') {
            return slug;
        }

        return "";
    }

    const getSlugOfVenue = (categoryUrl: any) => {
        const url = String(categoryUrl['post_link-venue']['url']);
        if(url === undefined) return "";

        const slug = url.split('/').filter(Boolean).pop();

        if(typeof slug === 'string') {
            return slug;
        }

        return "";
    }

    useEffect(() => {
        if (!newsLoaded) return;
        trackPageView({
            href: window.location.href, // URL der aktuellen Seite
          });

        let tempPosts = localStorage.getItem('news');
        if (tempPosts) {
            const parsedPosts: Post[] = JSON.parse(tempPosts);
            const postItem = getSinglePost(parsedPosts, postSlug) || null;

            if (postItem) {
                setPost(postItem);
            }
            else {
                navigate('/notfound');
            }  
        }
    }, [location, newsLoaded]);

    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
            newsLoaded ? (
                <ion-page class="sg-body post-page">
                <div className="page-container">
                <MetaHelmet 
                    title={(post?.title) + ' - Graz'}
                    desc={`Alle Details zu ${post?.title} in Graz findest du auf smartGastro.`}
                />
                    <div>
                        <img className="post-image" src={post?.featured_media} />
                        <span className="post-date ion-padding">{post?.publish_date}</span>
                        <div className="header-container ion-padding news">
                            {internalOrigin && <button className="arrow-back" style={{ backgroundImage: `url("/icons/back_arrow_white.svg")` }} onClick={() => navigate(-1)}></button>}
                            <h1 className="start-title">
                                {post?.title}
                            </h1>
                            <span onClick={() => shareUrl()} className="share-icon"></span>
                        </div>
                        <div className="ion-padding news-container">
                            <div className="news-content" dangerouslySetInnerHTML={{ __html: post?.content || '' }} />
                            <div className="news-links">
                                {post && post.links['categories'] && post.links['categories'].map((link: any, index: number) => (
                                    <>
                                    {index === 0 && link['post_link-category'] !== "" && (
                                        <h2 className="search-results-heading post">{t('categories')}</h2>
                                    )}
                                    {link['post_link-category'] !== "" && (
                                        <Link state={{ fromInternal: true }} className="search-results-item"
                                            key={index}
                                            to={link['post_link-category']}>
                                            {(language === 'de') &&
                                                <span key={index} className="search-results-item search-results-item-category news-link"
                                                        style={{ backgroundImage: `url("/icons/${getSlugOfCategory(link)}.svg")` }}>
                                                    {getCategoryBySlug(getSlugOfCategory(link))?.name_de}
                                                </span>}
                                            {(language === 'en') &&
                                                <span key={index} className="search-results-item search-results-item-category news-link"
                                                    style={{ backgroundImage: `url("/icons/${getSlugOfCategory(link)}.svg")` }}>
                                                        {getCategoryBySlug(getSlugOfCategory(link))?.name_en}
                                                </span>}
                                        </Link>
                                    )}
                                    </>
                                ))}

                                {post && post.links['venues'] && post.links['venues'].map((link: any, index: number) => (
                                    <>
                                    {link['post_link-venue'] !== "" && index === 0 && (
                                        <h2 className="search-results-heading post">{t('venues')}</h2>
                                    )}
                                    {link['post_link-venue'] !== "" && (
                                        <Link state={{ fromInternal: true }} className="search-results-item"
                                        to={`/betriebe/${getSlugOfVenue(link)}`}
                                        key={index}>
                                            {link['post_link-venue']['title']}
                                        </Link> 
                                    )}
                                    </>                             
                                ))}
                            </div>
                        </div>
                        
                    </div>
                    </div>
                </ion-page>
            ) : (
                <ion-page class="sg-body">
                    <LoadingScreen />
              </ion-page>
            )
        )
    );
}

export default pageTransition(Post);
