import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    calculateOpenHours,
    checkWarmKitchen,
    getOpenDOW,
    getVenueOpeningHours,
    openValueToTimeString
} from "../helper/Filter";
import { useTranslation } from "react-i18next";
import { getParentSlugOfId, shareUrl } from "../helper/Data";
import StartSettings from "../components/startSettings";
import pageTransition from "../components/pageTransition";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";
import { modalController, popoverController } from '@ionic/core';
import LoadingScreen from "../components/loadingScreen";
import axios from "axios";
import { isHoliday } from "../helper/Holiday";
import { RasterTileSource } from "maplibre-gl";


interface VenueProps {
    categoriesLoaded: boolean;
    venuesLoaded: boolean;
}

interface Venue {
    id: number;
    title: string;
    slug: string;
    category: number[];
    parent_category: number[];
    restaurant__gallery: string[];
    restaurant__closed: {
        from: string;
        until: string;
    };
    restaurant__mittagsteller: string;
    restaurant__website: string;
    restaurant__menu: string;
    restaurant__gps_lon: string;
    restaurant__gps_lat: string;
    restaurant__name: string;
    restaurant__opening_hours: {
        "restaurant__opening_hours-open": string;
        "restaurant__opening_hours-close": string;
        "restaurant__opening_hours-dow_till": string;
        "restaurant__opening_hours-warm_cuisine": boolean;
        "restaurant__opening_hours-dow_from": string;
    }[];
    restaurant__email: string;
    restaurant__phone_number: string;
    restaurant__address: string;
    restaurant__google_key: string;
    restaurant__views: string;
    google_key: string;
}


const Venue: React.FC<VenueProps> = ({ categoriesLoaded, venuesLoaded }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Use `useLocation` to extract venue
    const location = useLocation();
    const internalOrigin = location.state?.fromInternal || false;
    const path = location.pathname.substring(1);
    const venueSlug = path.split('/')[1];
    const pathname = location.pathname.endsWith('/')
    ? location.pathname.slice(0, -1)
    : location.pathname;

    const innerHeight = window.innerHeight ? window.innerHeight : undefined;

    const { trackPageView } = useMatomo();

    const [hasMittagsmenu, setHasMittagsmenu] = useState(false);
    const [showMenuPopup, setShowMenuPopup] = useState(false);

    const handleNoMenuClick = () => {
        setShowMenuPopup(true);

        setTimeout(() => {
            setShowMenuPopup(false);
        }, 5000);
    };

    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));
    const [language] = useState(localStorage.getItem('i18nextLng') || 'en');

    const [venue, setVenue] = useState<Venue | null>(null);
    const [categories, setCategories] = useState<any[]>([]);
    const [openStatus, setOpenStatus] = useState('geschlossen');
    const [openDays, setOpenDays] = useState<any[]>([]);
    const [openDOW, setOpenDOW] = useState<any[]>([]);
    const [gallery, setGallery] = useState<string[]>([]);

    const openGallery = (e: any) => {
        navigate(pathname+'/galerie', { state: { fromInternal: true } });
    };

    const incrementVenueViews = async (venueId: any) => {
        try {
          const query = await axios
            .get(`https://graz.smartgastro.at/wp-json/capi/v1/inc_venue_views`, {
              params: { id: venueId },
            })
            .then((res) => {
              console.log("Views incremented"); // Log the new view count
            });
        } catch (e) {
          // Handle error
          console.error(e);
        }
      };
      

    ////// Modal Definitions & Functions ///////

    //Categories Modal

    const categoriesModalRef = useRef<HTMLIonModalElement | null>(null);

    const openCategoriesModal = async () => {
        // Berechne die geschätzte Höhe des Modals
        const estimatedHeight = categories.length * 51 + 90; // 50px pro Kategorie + 100px für den Header
        let modalRatio = estimatedHeight / window.innerHeight;
        if (modalRatio > 1) modalRatio = 1;
    
        const modal = await modalController.create({
            component: 'div',
            mode: 'ios',
            componentProps: {
                innerHTML: `
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>${t('categories')}</ion-title>
                            <ion-buttons slot="end">
                                <ion-button class="close-modal-button" id="closeCategoriesModalButton">${t('close')}</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                        <div id="categoriesModalContent">
                            <ion-list lines="none" class="ion-padding">
                                ${categories.map((item) => `
                                    <ion-item class="category-link" data-url="/${getParentSlugOfId(item.parent_id)}/${item.slug}">
                                        <div class="venue-modal-category__image" slot="start">
                                            <img src="/icons/${item.slug}.svg" />
                                        </div>
                                        <label class="venue-modal-category__label">
                                            ${language === 'de' ? item.name_de : item.name_en}
                                        </label>
                                    </ion-item>
                                `).join('')}
                            </ion-list>
                        </div>
                    </ion-content>
                `,
            },
            cssClass: 'sg-modal categories',
            breakpoints: [0, 1],
            initialBreakpoint: modalRatio, // Berechneter Breakpoint
        });
    
        categoriesModalRef.current = modal;
        await modal.present();
    
        // Close-Button Funktionalität
        const closeButton = document.getElementById('closeCategoriesModalButton');
        if (closeButton) {
            closeButton.addEventListener('click', () => modal.dismiss());
        }
    
        // Navigation für Kategorien-Links
        const categoryLinks = document.querySelectorAll('.category-link');
        categoryLinks.forEach((link) => {
            link.addEventListener('click', (event) => {
                const target = event.currentTarget as HTMLElement;
                const url = target.getAttribute('data-url');
                if (url) {
                    navigate(url, { state: { fromInternal: true } });
                    modal.dismiss();
                }
            });
        });
    };
    
    
    //Hours Modal

    const hoursModalRef = useRef<HTMLIonModalElement | null>(null);

    const openHoursModal = async () => {
        // Berechne die geschätzte Höhe des Modals basierend auf den Öffnungszeiten
        const estimatedHeight = openDays.reduce((total, day) => {
            return total + (day.hours ? (day.hours_warm ? 87 : 63) : 0);
        }, 110); // +100px für den Header
    
        let modalRatio = estimatedHeight / window.innerHeight;
        if (modalRatio > 1) modalRatio = 1;
    
        const modal = await modalController.create({
            component: 'div',
            mode: 'ios',
            componentProps: {
                innerHTML: `
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>${t('openingHours')}</ion-title>
                            <ion-buttons slot="end">
                                <ion-button class="close-modal-button" id="closeModalButton">${t('close')}</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                        <div id="hoursModalContent">
                            <ion-list class="ion-padding">
                                ${openDays.slice(0, 8).map(day => `
                                    <ion-item>
                                        <label>
                                            <span>${day.name}</span>
                                            <p>${day.hours ? day.hours : t('closed')}</p>
                                            ${day.hours_warm ? `<p>${t('warm_kitchen')}: ${day.hours_warm}</p>` : ''}
                                        </label>
                                    </ion-item>
                                `).join('')}
                                ${openDays.slice(8, 9).map(day => day.hours ? `
                                    <ion-item>
                                        <label>
                                            <span>${day.name}</span>
                                            <p>${day.hours}</p>
                                            ${day.hours_warm ? `<p>${t('warm_kitchen')}: ${day.hours_warm}</p>` : ''}
                                        </label>
                                    </ion-item>
                                ` : '').join('')}
                            </ion-list>
                        </div>
                    </ion-content>
                `,
            },
            cssClass: 'sg-modal hours',
            breakpoints: [0, 1],
            initialBreakpoint: modalRatio, // Dynamische Initialhöhe
        });
    
        hoursModalRef.current = modal;
        await modal.present();
    
        // Close-Button Funktionalität
        const closeButton = document.getElementById('closeModalButton');
        if (closeButton) {
            closeButton.addEventListener('click', () => modal.dismiss());
        }
    };
    

    //Booking Modal

    const bookingModalRef = useRef<HTMLIonModalElement | null>(null);

    const openBookingModal = async () => {
        const now = new Date();
        const roundedDate = roundToNearest15Minutes(now);
        const formattedDate = roundedDate.toLocaleString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });

        const modalHeight = 310;
        let modalRatio = modalHeight / window.innerHeight;
        if (modalRatio > 1) modalRatio = 1;
    
        const modal = await modalController.create({
            component: 'div',
            mode: 'ios',
            componentProps: {
                innerHTML: `
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>${t('reservation')}</ion-title>
                            <ion-buttons slot="end">
                                <ion-button id="closeModalButton">${t('close')}</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                    <div id="bookingModalContent">
                            <div class="booking-modal-line">
                                <label style="padding-right: 16px;">${t('chooseTime')}</label>
                                <span id="dateTimeSpan">${formattedDate}</span>
                            </div>
                            <div class="booking-modal-line">
                                <label>Name</label>
                                <input
                                    class="input-name"
                                    id="nameInput"
                                    autocomplete="name"
                                    name="name"
                                    placeholder="${t('placeholderName')}"
                                />
                            </div>
                            <div class="booking-modal-line">                      
                                <label>${t('numberPeople')}</label>
                                <div class="number-people">
                                    <span class="sg-modal-button minus" id="decreasePeople"></span>
                                    <input
                                        class="input-number-people"
                                        id="numberPeopleInput"
                                        name="numberPeople"
                                        type="number"
                                        value="2"
                                    />
                                    <span class="sg-modal-button plus" id="increasePeople"></span>
                                </div>
                            </div>
                            <button type="submit" class="sg-button" id="submitBookingForm">
                                ${t('book')}
                            </button>
                            </div>
                    </ion-content>
                `,
            },
            cssClass: 'sg-modal booking',
            breakpoints: [0, 1],
            initialBreakpoint: modalRatio,
        });
        
        bookingModalRef.current = modal;
        await modal.present();
    
        const closeButton = document.getElementById('closeModalButton');
        if (closeButton) {
            closeButton.addEventListener('click', () => modal.dismiss());
        }
    
        // Attach event to open datetime popover
        const dtSpan = document.getElementById('dateTimeSpan');
        if (dtSpan) {
            dtSpan.addEventListener('click', async () => {
                await openDatetimePopover(); // Use the new popover function
            });
        }
    
        // Attach the logic for form submission
        const numberPeopleInput = document.getElementById('numberPeopleInput') as HTMLInputElement;
        const nameInput = document.getElementById('nameInput') as HTMLInputElement;
        const submitButton = document.getElementById('submitBookingForm');
        
        // Increase and decrease buttons for people count
        const decreaseButton = document.getElementById('decreasePeople');
        const increaseButton = document.getElementById('increasePeople');
        if (decreaseButton) {decreaseButton.addEventListener('click', () => changeNumberPeople(-1));}
        if (increaseButton) {increaseButton.addEventListener('click', () => changeNumberPeople(1));}
    
        const changeNumberPeople = (delta: number) => {
            if (numberPeopleInput) {
                let currentValue = parseInt(numberPeopleInput.value, 10) || 0;
                const newValue = Math.max(1, currentValue + delta);
                numberPeopleInput.value = newValue.toString();
            }
        };
    
        if (submitButton) {
            submitButton.addEventListener('click', (e) => {
                e.preventDefault();
                console.log('Send mail with:');
                const numberPeople = numberPeopleInput?.value;
                const personName = nameInput?.value;
                const resDateTime = dtSpan?.innerText;
                console.log(numberPeople + ' | ' + personName + ' | ' + resDateTime);
                sendMail(numberPeople, personName, resDateTime);
                modal.dismiss();
            });
        }

    };
    
    

    const roundToNearest15Minutes = (date: Date): Date => {
        const minutes = 15;
        const ms = 1000 * 60 * minutes; // 15 minutes in milliseconds
        return new Date(Math.ceil(date.getTime() / ms) * ms);
    };
    
    //DateTime Modal

    const datetimePopoverRef = useRef<HTMLIonPopoverElement | null>(null);

    const openDatetimePopover = async () => {
        const now = new Date();
        const roundedDate = roundToNearest15Minutes(now);
        const minDate = roundedDate.toISOString();

        const popover = await popoverController.create({
            component: 'div',
            mode: 'ios',
            componentProps: {
                innerHTML: `
                    <ion-datetime
                        class="sg-datetime"
                        name="dateTime"
                        id="customDatetime"
                        show-default-buttons="true"
                        prefer-wheel="true"
                        done-text=${t('confirm')}
                        cancel-text=${t('close')}
                        locale=${language+'-DE'}
                        first-day-of-week="1"
                        minute-values="0, 15, 30, 45"
                        min=${minDate}
                        mode="ios"
                    ></ion-datetime>
                `,
            },
            cssClass: 'datetime-popover',
        });
    
        datetimePopoverRef.current = popover;
        await popover.present();
    
        // Handle the submission and cancellation of the datetime selection
        const ionDatetime = document.getElementById('customDatetime') as HTMLIonDatetimeElement | null;
        if (ionDatetime) {
            ionDatetime.addEventListener('ionChange', (event) => {
                const dateValue = event.detail.value;
                console.log(dateValue);
    
                if (dateValue) {
                    const selectedDate = new Date(Array.isArray(dateValue) ? dateValue[0] : dateValue);
    
                    const formattedDate = selectedDate.toLocaleString('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                    });
                    console.log('date selected');
                    console.log(formattedDate);
    
                    const dtSpan = document.getElementById('dateTimeSpan');
                    if (dtSpan) {
                        dtSpan.textContent = formattedDate;
                    }
                } else {
                    console.error('Invalid date value:', dateValue);
                }
    
                popover.dismiss();
            });
        }
    };
    

    // Modals Clean Up on Component Dismiss
    useEffect(() => {
        return () => {
            if (categoriesModalRef.current) {
                categoriesModalRef.current.dismiss().then(() => {
                    categoriesModalRef.current = null;
                });
            }
            if (bookingModalRef.current) {
                bookingModalRef.current.dismiss().then(() => {
                    bookingModalRef.current = null;
                });
            }
            if (datetimePopoverRef.current) {
                datetimePopoverRef.current.dismiss().then(() => {
                    datetimePopoverRef.current = null;
                });
            }
            if (hoursModalRef.current) {
                hoursModalRef.current.dismiss().then(() => {
                    hoursModalRef.current = null;
                });
            }
        };
    }, []);
     

    ////// Reservation Functions /////// 
    const sendMail = (numberPeople: string | number | null | undefined, personName: string | number | null | undefined, resDateTime: string | string[] | null | undefined) => {
        if (!venue || !resDateTime) return;
    
        const textGerman = 'Liebes Team von ' + venue.title + ',%0A'
            + 'ich würde gerne am ' + resDateTime + ' bei euch reservieren. Bitte um Bestätigung meiner Anfrage für '
            + numberPeople + ' Personen.%0A%0A'
            + 'Diese Email wurde von einem Nutzer der Grazer Website www.smartgastro.at erstellt.%0A%0A'
            + 'Vielen Dank und liebe Grüße,%0A' + personName;
    
        const textEnglish = 'Dear ' + venue.title + ' team,%0A'
            + 'I would like to reserve a table for ' + numberPeople + ' people on ' + resDateTime
            + '.%0APlease confirm my reservation.%0A%0A'
            + 'This message was automatically generated by a user of smartgastro.at.%0A%0A'
            + 'Thank you and best regards,%0A' + personName;
    
        const text = language === 'de' ? textGerman : textEnglish;
        const subject = language === 'de' ? 'Reservierungsanfrage' : 'Booking request';
    
        const mailtoLink = `mailto:${venue.restaurant__email}?subject=${subject}&body=${text}`;
        window.location.href = mailtoLink;
    };
    

    ////// Helper Functions /////// 

    function getVenueBySlug(slug: string, venues: Venue[]): Venue | null {
        const foundVenue = venues.find((venue) => venue.slug === slug);
        return foundVenue || null;
    }

    function getCategoriesById(categoryIds: number[]) {
        let allCategories = localStorage.getItem('categories')
        if (allCategories && allCategories.length) {
            allCategories = JSON.parse(allCategories);

            let categories = [];

            for (let counter = 0; counter < categoryIds.length; counter++) {
                // @ts-ignore
                for (let i = 0; i < allCategories.length; i++) {
                    // @ts-ignore
                    if (categoryIds[counter] === allCategories[i].id) {
                        let cat = {
                            // @ts-ignore
                            'slug': allCategories[i].slug,
                            'id': categoryIds[counter],
                            // @ts-ignore
                            'name_de': allCategories[i].name_de,
                            // @ts-ignore
                            'name_en': allCategories[i].name_en,
                            // @ts-ignore
                            'parent_id': allCategories[i].parent_id
                        };

                        categories.push(cat);

                        if(cat.slug === 'menu') {
                            setHasMittagsmenu(true);
                        }
                    }
                }
            }
            setCategories(categories);
        }
    }

    function getOpeningHours(openingHours: Venue["restaurant__opening_hours"], language: string | null) {
        if(!openingHours) {
            setOpenStatus(t('no_regular_hours'));
            return;
        }

        // @ts-ignore
        setOpenDOW(getOpenDOW(openingHours, language));

        // @ts-ignore
        let isWarmKitchen = checkWarmKitchen(openingHours);

        if (isWarmKitchen) {
            // @ts-ignore
            let days = calculateOpenHours(openingHours, true, language || 'german');
            setOpenDays(days);
        } else {
            // @ts-ignore
            let days = calculateOpenHours(openingHours, false, language || 'german');
            setOpenDays(days);
        }

        let currentDate = new Date();
        let hour = currentDate.getHours();
        let minutes = currentDate.getMinutes() / 60;
        let dow = currentDate.getDay();
        if (dow === 0) {
            dow = 7;
        }
        //Feiertagcheck
        const holidayCheck = isHoliday(currentDate);
        if (holidayCheck.isHoliday) {
            dow = 8;
        }
        
        let time = hour + minutes;

        let openValue = getVenueOpeningHours(openingHours, time, dow);
        if (openValue > 0) {
            setOpenStatus(t('todayUntil') + openValueToTimeString(openValue));
        } else if (openValue < 0) {
            setOpenStatus(t('closed') + t('opensAt') + openValueToTimeString((-openValue)));
        } else {
            setOpenStatus(t('closed'));
        }

    }

    ////// Page useEffect ///////   
    useEffect(() => {
        trackPageView({
            href: window.location.href, // URL der aktuellen Seite
        });
        if (!categoriesLoaded || !venuesLoaded) return;

        let tempVenues = localStorage.getItem('venues');
        if (tempVenues) {
            let venues = JSON.parse(tempVenues);
            const currentVenue = getVenueBySlug(venueSlug || '', venues);

            if (currentVenue) {
                setVenue(currentVenue);
                setGallery(currentVenue.restaurant__gallery);
                getCategoriesById(currentVenue.category);
                getOpeningHours(currentVenue.restaurant__opening_hours, language || 'german');
                incrementVenueViews(currentVenue.id);
            }
            else {
                navigate('/notfound');
            }
        }
    }, [location, categoriesLoaded, venuesLoaded]);


    ////// Return Statement ///////   
    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
            categoriesLoaded && venuesLoaded && venue ? (
                <ion-page class="sg-body">
                <div className="page-container">
                <MetaHelmet
                    title={(venue?.title) + ' - Graz'}
                    desc={`Alle Details zu ${venue.title} in Graz findest du auf smartGastro. Entdecke Öffnungszeiten, Speisekarten und mehr!`}
                />
                    <ion-header mode="ios">
                        <div className="header-container venue ion-padding">
                            {internalOrigin && <button className="arrow-back" style={{ backgroundImage: `url("/icons/back_arrow_white.svg")` }} onClick={() => navigate(-1)}></button>}
                            <h1 className="start-title left">{venue.title}</h1>
                            <span onClick={() => shareUrl()} className="share-icon"></span>
                        </div>
                    </ion-header>
                    {/* Grid Container */}
                    <div className="grid-container venue">
                        <div className="venue-card gallery" onClick={openGallery}>
                            {gallery.length > 0 && (
                                <>
                                    <img src={gallery[0]} />
                                    <svg
                                        className="photo-svg"
                                        version="1.1"
                                        id="Ebene_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 30 30"
                                        xmlSpace="preserve"
                                        fill="#fff"
                                    >
                                        <g>
                                            <path className="st0" d="M30,18.3c0-2.7,0-5.4,0-8c0-1.3-0.5-2.5-1.6-3.4c-0.7-0.6-1.5-1-2.4-1c-0.3,0-0.6,0-0.9,0c-0.1,0-0.3,0-0.4,0
		l-0.4,0c-0.5,0-1,0-1.5,0c-0.3-1-0.7-1.9-1.3-2.6c-0.7-0.9-1.5-1.5-2.5-1.7c-0.1,0-0.3-0.1-0.4-0.1c-2.3,0-4.6,0-6.9,0
		c-1.3,0-2.4,0.6-3.3,1.8C7.9,4,7.5,4.8,7.2,5.8l-0.1,0L7,5.8c-0.9,0-1.8,0-2.7,0c-2.2,0-4,1.5-4.3,3.8l0,14.8l0.1,0.3
		c0,0.2,0.1,0.4,0.2,0.6c0.3,1.1,0.9,1.9,1.7,2.5c0.5,0.4,1.1,0.6,1.8,0.7l0.1-0.2c0,0,0,0,0,0l0,0.3l22.2,0l0.2,0
		c0.1,0,0.3,0,0.4-0.1c1.4-0.3,2.4-1.2,3-2.6c0.3-0.6,0.4-1.2,0.4-1.9C30,22.1,30,20.2,30,18.3z M2.4,10.1c0-1,0.7-1.8,1.7-1.9
		c0.3,0,0.6,0,0.8,0c0.1,0,0.2,0,0.3,0l0.2,0c0.6,0,1.2,0,1.8,0l0.9,0c0,0,0,0,0,0c0.5,0,1-0.4,1.1-0.9c0.2-0.9,0.5-1.6,0.8-2.2
		c0.3-0.5,0.6-0.8,0.9-1c0.2-0.2,0.5-0.2,0.7-0.2c2.2,0,4.5,0,6.7,0c0.1,0,0.2,0,0.4,0.1c0.5,0.2,0.8,0.6,1.2,1.2
		c0.3,0.6,0.6,1.2,0.8,2c0.2,0.7,0.6,1,1.3,1l3.6,0c0,0,0,0,0,0c0.8,0,1.4,0.4,1.8,1.2c0.1,0.3,0.2,0.6,0.2,0.9c0,3.4,0,6.9,0,10.3
		l0,3.4c0,0.8-0.5,1.7-1.2,1.9c-0.2,0.1-0.4,0.1-0.6,0.1c-2.8,0-5.7,0-8.5,0l-5.2,0c-2.6,0-5.1,0-7.7,0c0,0,0,0,0,0
		c-1.1,0-1.6-0.8-1.8-1.2c-0.1-0.3-0.2-0.7-0.2-1c0-3.5,0-7.1,0-10.6L2.4,10.1z"/>
                                            <path className="st0" d="M14.9,23.7C15,23.7,15,23.7,14.9,23.7c3.6,0,6.5-2.9,6.6-6.5v0c0-3.6-2.9-6.5-6.5-6.5c0,0,0,0,0,0
		c-3.6,0-6.5,2.9-6.5,6.5C8.4,20.7,11.3,23.6,14.9,23.7z M15,13C15,13,15,13,15,13c2.3,0,4.2,1.9,4.2,4.2c0,1.1-0.4,2.2-1.2,3
		c-0.8,0.8-1.8,1.2-2.9,1.2c0,0,0,0,0,0c-1.1,0-2.2-0.4-2.9-1.2c-0.8-0.8-1.2-1.8-1.2-3C10.8,14.8,12.7,13,15,13z"/>
                                            <path className="st0" d="M23.3,11.4C23.4,11.4,23.4,11.4,23.3,11.4c0.3,0,0.6,0,0.8,0c0.1,0,0.2,0,0.4,0l0.3,0c0.3,0,0.6,0,0.9,0
		c0.1,0,0.3,0,0.4-0.1c0.4-0.2,0.6-0.7,0.4-1.2c-0.1-0.4-0.5-0.7-0.9-0.7l-2.3,0c-0.5,0-0.8,0.3-0.9,0.8c-0.1,0.3,0,0.7,0.2,0.9
		C22.8,11.3,23.1,11.4,23.3,11.4z"/>
                                        </g> </svg>
                                </>
                            )}
                            {gallery.length < 1 && (
                                <div
                                    className="gallery-empty"
                                    style={{ backgroundImage: `url("/icons/photo-white.svg")` }}
                                ></div>
                            )}
                        </div>
                        <div className="venue-card hours" onClick={openHoursModal}>
                            <h2 className="venue-card__heading">{t('openingHours')}</h2>
                            <div className="venue-card__weekdays">
                                {openDOW.map((item) => {
                                    if (item.state == true) {
                                        return (
                                            <span className="weekday open" key={item.name}>
                                                <div>{item.name}</div>
                                            </span>
                                        );
                                    } else {
                                        return (
                                            <span className="weekday" key={item.name}>
                                                <div>{item.name}</div>
                                            </span>
                                        );
                                    }
                                })}
                            </div>
                            <span className="restaurant-open-status">{openStatus}</span>
                        </div>
                        {categories[0].slug !== 'allgemein' && (
                        <div className="venue-card categories" onClick={openCategoriesModal}>
                            <h2 className="venue-card__heading">{t('categories')}</h2>
                                <div>
                                {categories.map((item) => {
                                    if (categories.indexOf(item) < 4) {
                                        return (
                                            <span
                                                className="category-item"
                                                key={item.id}
                                                style={{
                                                    backgroundImage: `url("/icons/${item.slug}.svg")`,
                                                }}
                                            ></span>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                        )}
                        <div className="venue-card map">
                            <span>{venue.google_key}</span>
                            <iframe
                                style={{ border: 0 }}
                                loading="lazy"
                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyARvPmX_dtbPov8LDKkleiAYiKLGzyR5vg&q=place_id:${venue.restaurant__google_key}`}
                            ></iframe>
                        </div>

                        {/* Links Container */}
                        <div className="fullwidth-links-container">
                            {venue.restaurant__email && (
                                <span
                                    style={{ backgroundImage: `url("/icons/mail-green.svg")` }}
                                    className="venue-card menu half"
                                    onClick={openBookingModal}
                                >
                                    {t('request-booking-table')}
                                </span>
                            )}
                            {venue.restaurant__menu && (
                                <Link to={venue.restaurant__menu}
                                    target="blank"
                                    style={{ backgroundImage: `url("/icons/menu2-green.svg")` }}
                                    className="venue-card menu half"
                                >
                                    {t('menu')}
                                </Link>
                            )}
                            {hasMittagsmenu && (
                                <>
                                    {venue.restaurant__mittagsteller && venue.restaurant__mittagsteller.length > 0 ? (
                                        <Link to={venue.restaurant__mittagsteller}
                                            target="blank"
                                            style={{ backgroundImage: `url("/icons/menu-green.svg")` }}
                                            className="venue-card mittag half"
                                        >
                                            {t('mittagsmenu')}
                                        </Link>
                                    ) : (
                                        <>
                                        <div style={{ backgroundImage: `url("/icons/menu-green.svg")` }}
                                            className="venue-card mittag half"
                                            onClick={handleNoMenuClick}>
                                            {t('mittagsmenu')} 
                                            <span className="additional-information">{t('local-infos')} </span>
                                        </div>

                                        {showMenuPopup && (
                                            <div className="popup__no-menu-online">
                                                {t('no-online-menu')}       
                                            </div>
                                        )}
                                        </>
                                    )
                                }
                                </>
                            )}
                            
                        </div>
                    </div>

                    {/* Contact Container */}
                    <div className="venue-contact-container">
                        {venue.restaurant__website && (
                            <a
                                style={{ backgroundImage: `url("/icons/website-green.svg")` }}
                                className="contact-link"
                                href={venue.restaurant__website}
                            ></a>
                        )}
                        {venue.restaurant__phone_number && (
                            <a
                                style={{ backgroundImage: `url("/icons/phone-green.svg")` }}
                                className="contact-link"
                                href={`tel:${'+'+venue.restaurant__phone_number}`}
                            ></a>
                        )}
                    </div>
                    </div>
                </ion-page>
            ) : (
                <ion-page class="sg-body">
                    <LoadingScreen />
              </ion-page>
            )
        )
    );
}

export default pageTransition(Venue);
