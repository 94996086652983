import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import pageTransition from "../components/pageTransition";
import { useLocation, useNavigate } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";

const DataPrivacy: React.FC = () => {
    const { t } = useTranslation();

    // Load settings
    const [language] = useState(localStorage.getItem('i18nextLng') || 'en');

    const location = useLocation();
    const internalOrigin = location.state?.fromInternal || false;
    const { trackPageView } = useMatomo();

    const navigate = useNavigate();
  
    useEffect(() => {
      trackPageView({
        href: window.location.href, // URL der aktuellen Seite
      });
    }, [location]);

    return (
        <ion-page class="sg-body generic">
            <div className="page-container">
            <MetaHelmet 
                title={t('data-privacy')} 
            />
            <img className="post-image" src={'/images/sG_fallback_small.jpeg'} />
            <div className="header-container ion-padding news">
            {internalOrigin && <button className="arrow-back" style={{ backgroundImage: `url("/icons/back_arrow_white.svg")` }} onClick={() => navigate(-1)}></button>}
                <h1 className="start-title">
                    {t('data-privacy')}
                </h1>
            </div>
            {(language === 'de') &&
                <div className="generic-page german">
                    <p>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung.</p>

                    <h3>Daten von gastronomischen Betrieben</h3>
                    <p>Wir haben in unserer Datenbank wichtige Informationen zu gastronomischen Betrieben gespeichert. Dazu gehören:</p>
                    <ul>
                        <li>Bezeichnung</li>
                        <li>Öffnungszeiten</li>
                        <li>Leistungen (Angebot der Betriebe)</li>
                        <li>Geografische Daten (Adresse und Koordinaten)</li>
                        <li>Kontaktdaten (E-Mail Adresse und Telefonnummer)</li>
                        <li>Links zu den jeweiligen Webseiten und den dazugehörigen Speisekarten</li>
                        <li>Bilder</li>
                    </ul>
                    <p>Die Information zu den angezeigten Betrieben setzen sich aus verschiedenen Quellen zusammen. Diese bestehen aus Webseiten der Betriebe, direkte Auskünfte bei den Betrieben, Erfahrungen der Websitebetreiber und Dritten sowie aus öffentlichen Publikationen (Zeitungsartikeln, Social-Media Beiträgen etc.).</p>
                    <p>Wir erhalten und verarbeiten diese Daten aufgrund von berechtigtem Interesse gegenüber den Betrieben oder denen eines Dritten. Das berechtigte Interesse beruht auf der Annahme, dass die Informationen auf der Webseite einen Vorteil für die Nutzer sowie für die gelisteten Betriebe bieten.</p>
                    <p>Allgemein besteht das Recht auf Berichtigung jeglicher Informationen und falls nötig eine Löschung der Daten. Wir bitten Sie, eine Berichtigung oder Löschung der zu Ihrem Betrieb angehörigen Daten uns in schriftlicher Form an <a href="mailto:info@smartgastro.at">info@smartgastro.at</a> zu schicken.</p>

                    <h3>Anonyme Nutzungsdaten mit Matomo</h3>
                    <p>Diese Webseite verwendet Matomo, eine Open Source, selbstgehostete Software, um anonyme Nutzungsdaten für diese Webseite zu sammeln und zu analysieren.</p>
                    <p>Die Daten zum Verhalten der Besucher werden gesammelt, um eventuelle Probleme wie nicht gefundene Seiten, Suchmaschinenprobleme oder unbeliebte Seiten herauszufinden. Sobald die Daten (Anzahl der Besucher, die Fehlerseiten oder nur eine Seite sehen, usw.) verarbeitet werden, erzeugt Matomo Berichte für die Webseitenbetreiber, damit diese darauf reagieren können (Layoutveränderungen, neue Inhalte, usw.).</p>
                    <p>Matomo verarbeitet die folgenden Daten:</p>
                    <ul>
                        <li>Anonymisierte IP-Adressen indem die letzten 2 bytes entfernt werden (also 198.51.0.0 anstatt 198.51.100.54)</li>
                        <li>Pseudoanonymisierter Standort (basierend auf der anonymisierten IP-Adresse)</li>
                        <li>Datum und Uhrzeit</li>
                        <li>Titel & URL der aufgerufenen Seite</li>
                        <li>URL der vorhergehenden Seite (sofern diese das erlaubt)</li>
                        <li>Bildschirmauflösung</li>
                        <li>Lokale Zeit</li>
                        <li>Dateien, die angeklickt und heruntergeladen wurden</li>
                        <li>Externe Links</li>
                        <li>Dauer des Seitenaufbaus</li>
                        <li>Land, Region, Stadt (mit niedriger Genauigkeit aufgrund der IP-Adresse)</li>
                        <li>Hauptsprache des Browsers</li>
                        <li>User Agent des Browsers</li>
                        <li>Interaktionen mit Formularen (aber nicht deren Inhalt)</li>
                    </ul>
                    <p>Die Datenverarbeitung basiert auf dem Prinzip des legitimen Interesses.</p>
                    <p>Verarbeiten der Daten hilft uns herauszufinden, was auf unserer Seite funktioniert und was nicht. Zum Beispiel finden wir damit heraus, ob die Inhalte gut ankommen oder wie wir die Struktur der Webseite verbessern können. Unser Team profitiert davon und kann darauf reagieren. Aufgrund der Datenverarbeitung profitieren Sie somit von einer Webseite, die laufend besser wird.</p>

                    <h3>Essentielle Cookies und Local Storage</h3>
                    <p>Unsere Website verwendet Cookies und den Local Storage. Hierbei handelt es sich um kleine Textdateien, die mithilfe Ihres Browsers auf Ihrem Endgerät gespeichert werden. Diese Dateien richten keinen Schaden an und sind notwendig, um unsere Webseite nutzerfreundlich zu gestalten.</p>
                    <p>Einige dieser Daten verbleiben auf Ihrem Endgerät, bis Sie sie manuell löschen, und ermöglichen uns, Ihre Einstellungen beim nächsten Besuch wiederzuerkennen. Wir verwenden nur für den Websitebetrieb essentielle Cookies und Local Storage.</p>
                    <p>Sie haben jederzeit die Möglichkeit, alle lokal gespeicherten Website-Daten über die Einstellungsseite zu löschen.</p>
                    <p>Auf unserer Seite werden die folgenden Cookies gespeichert:</p>
                    <ul>
                        <li>HolidayInfo: Erstellt, wenn ein Feiertag ausgewählt wurde; ist einen Tag gültig.</li>
                    </ul>
                    <p>Im Local Storage speichern wir die folgenden Einträge:</p>
                    <ul>
                        <li>categories: Enthält die Kategorien von smartGastro und wird regelmäßig vom Backend geladen.</li>
                        <li>events: Enthält die Events von smartGastro und wird regelmäßig vom Backend geladen.</li>
                        <li>news: Enthält die News von smartGastro und wird regelmäßig vom Backend geladen.</li>
                        <li>venues: Enthält die Betriebe von smartGastro und wird regelmäßig vom Backend geladen.</li>
                        <li>ads: Enthält die Werbeanzeigen von smartGastro und wird regelmäßig vom Backend geladen.</li>
                        <li>last-update: Das Datum, wann die oben genannten Daten zuletzt vom Backend abgerufen wurden.</li>
                        <li>i18nextLang: Die ausgewählte Sprache.</li>
                        <li>settings-set: Ein Boolean-Wert, der angibt, ob der Nutzer die Einstellungen bestätigt hat.</li>
                        <li>consent-given: Datum und Uhrzeit, zu denen der Nutzer die Datenschutzrichtlinien bestätigt hat.</li>
                    </ul>
                    <p>Diese Daten werden im Local Storage gespeichert, um die Ladezeiten unserer Website zu verkürzen und ein optimales Nutzererlebnis zu gewährleisten.</p>

                    <h3>Webhosting</h3>
                    <p>Bei dem Besuch auf unserer Website werden gewisse essentielle Informationen erstellt und auf dem Webhosting Server gespeichert. Wir verwenden den Webhosting Anbieter: Hetzner Online GmbH</p>
                    <p>Mehr Informationen dazu, wie Ihre Daten bei diesem Anbieter gespeichert werden, finden Sie hier: <a href="https://www.hetzner.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.hetzner.com/legal/privacy-policy</a></p>
                    <p>Die Daten dieser Webseite und Matomo werden in Deutschland gehostet. Die Daten verlassen nie die EU.</p>

                    <h3>Google Maps</h3>
                    <p>Diese Webseite verwendet das Produkt Google Maps von Google LLC zur Darstellung des Standortes der Betriebe. Durch Nutzung dieser Webseite erklären Sie sich mit der Erfassung, Bearbeitung sowie Nutzung der automatisiert erhobenen Daten durch Google LLC, deren Vertreter sowie Dritter einverstanden.</p>
                    <p>Die Datenschutzerklärung von Google finden Sie unter: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a></p>
                </div>
            }
            {(language !== 'de') &&
                <div className="generic-page english">
                    <p>The protection of your personal data is of particular concern to us. We process your data exclusively on the basis of legal provisions (GDPR, TKG 2003). In this privacy information, we inform you about the most important aspects of data processing.</p>

                    <h3>Data of Gastronomic Establishments</h3>
                    <p>We have stored important information about gastronomic establishments in our database. These include:</p>
                    <ul>
                        <li>Name</li>
                        <li>Opening hours</li>
                        <li>Services (offerings of the establishments)</li>
                        <li>Geographic data (address and coordinates)</li>
                        <li>Contact details (email address and phone number)</li>
                        <li>Links to the respective websites and menus</li>
                        <li>Images</li>
                    </ul>
                    <p>The information displayed on establishments is gathered from various sources. These include websites of the establishments, direct information from the establishments, experiences of the website operators and third parties, as well as public publications (newspaper articles, social media posts, etc.).</p>
                    <p>We receive and process this data due to legitimate interests concerning the establishments or those of a third party. The legitimate interest is based on the assumption that the information on the website provides a benefit for both the users and the listed establishments.</p>
                    <p>In general, there is a right to correct any information and, if necessary, to delete the data. We ask you to send a correction or deletion of data related to your establishment in writing to <a href="mailto:info@smartgastro.at">info@smartgastro.at</a>.</p>

                    <h3>Anonymous Usage Data with Matomo</h3>
                    <p>This website uses Matomo, an open-source, self-hosted software to collect and analyze anonymous usage data for this website.</p>
                    <p>Data on visitor behavior is collected to identify potential issues such as pages not found, search engine issues, or unpopular pages. Once the data (e.g., the number of visitors viewing error pages or only one page, etc.) is processed, Matomo generates reports for the website operators, enabling them to respond (layout changes, new content, etc.).</p>
                    <p>Matomo processes the following data:</p>
                    <ul>
                        <li>Anonymized IP addresses by removing the last 2 bytes (e.g., 198.51.0.0 instead of 198.51.100.54)</li>
                        <li>Pseudonymized location (based on the anonymized IP address)</li>
                        <li>Date and time</li>
                        <li>Title & URL of the viewed page</li>
                        <li>URL of the previous page (if it allows)</li>
                        <li>Screen resolution</li>
                        <li>Local time</li>
                        <li>Files clicked and downloaded</li>
                        <li>External links</li>
                        <li>Page loading duration</li>
                        <li>Country, region, city (low accuracy based on IP address)</li>
                        <li>Main language of the browser</li>
                        <li>User agent of the browser</li>
                        <li>Interactions with forms (but not their content)</li>
                    </ul>
                    <p>Data processing is based on the principle of legitimate interest.</p>
                    <p>Processing the data helps us identify what works on our site and what does not. For example, we can find out if the content is well received or how we can improve the website structure. Our team benefits from this and can respond accordingly. Through data processing, you benefit from a continuously improving website.</p>

                    <h3>Essential Cookies and Local Storage</h3>
                    <p>Our website uses cookies and local storage. These are small text files stored on your device through your browser. These files are harmless and necessary to make our website user-friendly.</p>
                    <p>Some of this data remains on your device until you manually delete it, allowing us to recognize your settings on your next visit. We only use essential cookies and local storage for the operation of the website.</p>
                    <p>You have the option at any time to delete all locally stored website data via the settings page.</p>
                    <p>The following cookies are stored on our website:</p>
                    <ul>
                        <li>HolidayInfo: Created when a holiday is selected; valid for one day.</li>
                    </ul>
                    <p>The following entries are stored in Local Storage:</p>
                    <ul>
                        <li>categories: Contains the categories from smartGastro, which are regularly loaded from the backend.</li>
                        <li>events: Contains the events from smartGastro, which are regularly loaded from the backend.</li>
                        <li>news: Contains the news from smartGastro, which are regularly loaded from the backend.</li>
                        <li>venues: Contains the establishments from smartGastro, which are regularly loaded from the backend.</li>
                        <li>ads: Contains advertisements from smartGastro, which are regularly loaded from the backend.</li>
                        <li>last-update: The date when the above data was last retrieved from the backend.</li>
                        <li>i18nextLang: The selected language.</li>
                        <li>settings-set: A boolean value indicating if the user has confirmed the settings.</li>
                        <li>consent-given: Date and time when the user accepted the privacy policy.</li>
                    </ul>
                    <p>This data is stored in Local Storage to reduce our website's loading times and ensure an optimal user experience.</p>

                    <h3>Web Hosting</h3>
                    <p>When visiting our website, certain essential information is generated and stored on the web hosting server. We use the web hosting provider: Hetzner Online GmbH</p>
                    <p>For more information on how your data is stored with this provider, please see here: <a href="https://www.hetzner.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.hetzner.com/legal/privacy-policy</a></p>
                    <p>The data of this website and Matomo are hosted in Germany. The data never leaves the EU.</p>

                    <h3>Google Maps</h3>
                    <p>This website uses Google Maps from Google LLC to display the location of establishments. By using this website, you consent to the collection, processing, and use of automatically collected data by Google LLC, its representatives, and third parties.</p>
                    <p>You can find Google’s privacy policy here: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a></p>
                </div>
            }
            </div>
        </ion-page>
    );
}

export default pageTransition(DataPrivacy);