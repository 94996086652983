import React, { useRef, useState, useEffect } from "react";
import { filterVenues, formatDateToDateString, formatDateToTimeString, getDistance, getVenueById, shareUrl } from "../helper/Data";
import { getVenueOpeningHours, openValueToTimeString } from "../helper/Filter";
import VenueCard from "../components/venueCard";
import CategoryMap from "../components/categoryMap";
import pageTransition from "../components/pageTransition";
import AdCard from "../components/adCard";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import StartSettings from "../components/startSettings";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";
import { modalController, popoverController } from "@ionic/core";
import LoadingScreen from "../components/loadingScreen";

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isHoliday } from "../helper/Holiday";
import Cookies from "js-cookie";

interface CategoryProps {
    categoriesLoaded: boolean;
    venuesLoaded: boolean;  // Neue Prop für den venuesLoaded-Status
}

interface Category {
    id: number;
    parent_id: number;
    slug: string;
    name_de: string;
    description_de: string;
    name_en: string;
    description_en: string;
    search_str: string;
    venue_count: number;
}

interface Ad {
    'ad_field-status': boolean;
    'ad_field-text': string;
    'ad_field-image': {
      url: string;
    };
  }

const Category: React.FC<CategoryProps> = ({ categoriesLoaded, venuesLoaded }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Use `useLocation` to extract category and parent
    const location = useLocation();
    const internalOrigin = location.state?.fromInternal || false;
    const { trackPageView } = useMatomo();
    const pathname = location.pathname.endsWith('/')
    ? location.pathname.slice(0, -1)
    : location.pathname;
    const segments = pathname.substring(1).split('/'); 
    const categoryName = segments.pop();
    const parentCategoryName = segments.length > 0 ? segments.pop() : "";   

    const innerHeight = window.innerHeight ? window.innerHeight : undefined;

    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));
    const [ads, setAds] = useState<Ad[] | null>(null);
    const [adsFrequency, setAdsFrequency] = useState(0);
    const [language] = useState(localStorage.getItem('i18nextLng') || 'de');
    const [sortingPreference, setSortingPreference] = useState(localStorage.getItem('sorting') || 'popularity');

    // 1. venues of category
    const [venues, setVenues] = useState<any[]>([])
    // 2. venues filtered by category
    const [filteredVenues, setFilteredVenues] = useState<any[]>([])

    const filteredVenuesRef = useRef(filteredVenues);

    useEffect(() => {
    filteredVenuesRef.current = filteredVenues;
    }, [filteredVenues]); 

    // 3. venues filterd by time (all venues will be in OPEN when no time filter is set)
    const [venuesOpen, setVenuesOpen] = useState([]);
    const [venuesClosed, setVenuesClosed] = useState([]);
    // 4. mapped venues for the marker
    const [venuesMarker, setVenuesMarker] = useState([]);
    const [category, setCategory] = useState<Category | null>(null);


    // datefilter
    const [datetimeValue, setDatetimeValue] = useState(new Date())
    const [datetimeFilterOn, setDatetimeFilterOn] = useState(false);

    /// Holiday Toast
    const showHolidayToast = async () => {
        // Create the toast element
        const toast = document.createElement('ion-toast');
        toast.message = t('holiday_info');
        toast.duration = 10000; // Show for 10
        toast.position = 'bottom'; // Or 'bottom', 'middle'
            // Add buttons to the toast
        toast.mode ='ios';
        toast.swipeGesture='vertical';
        toast.buttons = [
            {
            text: t('close'),
            role: 'cancel',
            },
        ];
        toast.cssClass = 'info-toast';
    
        // Append the toast to the document body
        document.body.appendChild(toast);
    
        // Present the toast
        await toast.present();
    };
    
    ////// Slider ///////

    const sliderRef = useRef<Slider>(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const [sliderSettings, setSliderSettings] = useState({
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 3,
        autoplay: false,
        arrows: false,
        swipe: false,
    });

    const changeSlide = (index: number) => {
        if (sliderRef.current) {
            setTimeout(() => {
                sliderRef.current!.slickGoTo(index);
            }, 100); // Delay by 100ms to avoid race condition
        }
    };

    //slider segment

    useEffect(() => {
        const handleSegmentChange = (event: Event) => {
            const customEvent = event as CustomEvent;
            const selectedSlide = customEvent.detail.value;
           // debugger
            if (selectedSlide == 1) {
                // @ts-ignore
                document.getElementById('header-category').className = 'cat-header transparent ion-padding';
            } else {
                // @ts-ignore
                document.getElementById('header-category').className = 'cat-header ion-padding';
            }
            changeSlide(selectedSlide);
        };
    
        const observer = new MutationObserver(() => {
            const segmentListMap = document.getElementById('segmentListMap');
            if (segmentListMap) {
                segmentListMap.addEventListener('ionChange', handleSegmentChange as EventListener);
    
                // Once the element is found and the event listener is added, disconnect the observer
                observer.disconnect();
            }
        });
    
        // Start observing the DOM for changes
        observer.observe(document.body, { childList: true, subtree: true });
    
        // Cleanup: remove the event listener and disconnect the observer
        return () => {
            const segmentListMap = document.getElementById('segmentListMap');
            segmentListMap?.removeEventListener('ionChange', handleSegmentChange as EventListener);
            observer.disconnect();
        };
    }, []);

    ////// Modal Definitions & Functions ///////

    // Description Modal

    const descriptionModalRef = useRef<HTMLIonModalElement | null>(null);

    const openDescriptionModal = async () => {
        const descriptionText = language === 'de' ? category?.description_de : category?.description_en;

        const modal = await modalController.create({
            component: 'div',
            mode: 'ios',
            componentProps: {
                innerHTML: `
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>${t('description')}</ion-title>
                            <ion-buttons slot="end">
                                <ion-button id="closeModalButton">${t('close')}</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content class="ion-padding">
                        <div class="modal-description-text">
                            ${descriptionText || ''}
                        </div>
                    </ion-content>
                `,
            },
            cssClass: 'filter-modal sg-modal', // Custom class for styling the modal
            breakpoints: [0, 0.25, 0.5, 0.75],
            initialBreakpoint: 0.5, // Start the modal at 25% of the screen height
        });
        descriptionModalRef.current = modal; // Store the modal instance
        await modal.present();

        // Attach the close functionality to the close button
        const closeButton = document.getElementById('closeModalButton');
        if (closeButton) {
            closeButton.addEventListener('click', () => modal.dismiss());
        }
    };

    // Sorting Modal

    const sortingModalRef = useRef<HTMLIonModalElement | null>(null);

    const openSortingModal = async () => {
        const modal = await modalController.create({
            component: 'div',
            mode: 'ios',
            componentProps: {
                innerHTML: `
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>${t('sorting_order')}</ion-title>
                            <ion-buttons slot="end">
                                <ion-button class="close-modal-button" id="closeModalButton">${t('done')}</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content class="ion-padding">
                        <div class="filter-group sorting">
                            <ion-radio-group id="radioSorting" value="${sortingPreference}">
                                <div class="sorting-item">
                                    <ion-radio mode="ios" value="popularity" id="radioPopularity"></ion-radio>
                                    <label for="radioPopularity">${t('popularity')}</label>
                                </div>
                                <div class="sorting-item">
                                    <ion-radio mode="ios" value="distance" id="radioDistance"></ion-radio>
                                    <label for="radioDistance">${t('distance')}</label>
                                </div>
                            </ion-radio-group>
                        </div>
                    </ion-content>
                `,
            },
            cssClass: 'sortby-modal sg-modal', // Klassenname für das Styling
            breakpoints: [0, 1],
            initialBreakpoint: 0.3, // Startpunkt bei 25% der Bildschirmhöhe
        });
        sortingModalRef.current = modal; // Instanz des Modals speichern
        await modal.present();
    
        // Eventlistener für Klicks, die das Modal schließen und die Sortierung ändern
        const radioPopularity = document.getElementById('radioPopularity');
        const radioDistance = document.getElementById('radioDistance');
    
        if (radioPopularity) {
            radioPopularity.addEventListener('click', async () => {
                changeSortingPreference('popularity');
                await modal.dismiss();
            });
        }
    
        if (radioDistance) {
            radioDistance.addEventListener('click', async () => {
                changeSortingPreference('distance');
                await modal.dismiss();
            });
        }
    
        const closeButton = document.getElementById('closeModalButton');
        if (closeButton) {
            closeButton.addEventListener('click', () => modal.dismiss());
        }
    };

    // Filter Modal

    const filterModalRef = useRef<HTMLIonModalElement | null>(null);

    const [filterCategories, setFilterCategories] = useState([]);
    const [hasGastgarten, setHasGastgarten] = useState(false);
    const [hasMittagsmenu, setHasMittagsmenu] = useState(false);
    const [hasParkplatz, setHasParkplatz] = useState(false);
    const [hasSchnell, setHasSchnell] = useState(false);
    const [hasVegetarian, setHasVegatarian] = useState(false);
    const [hasVegan, setHasVegan] = useState(false);
    const [hasGluten, setHasGluten] = useState(false);
    const [hasSpielplatz, setHasSpieplatz] = useState(false);
        
    const openFilterModal = async () => {
        const modalHeight = 270;
        let modalRatio = modalHeight / window.innerHeight;
        if (modalRatio > 1) modalRatio = 1;

        const modal = await modalController.create({
            component: 'div',
            mode: 'ios',
            componentProps: {
                innerHTML: `
                    <ion-header>
                        <ion-toolbar>
                            <ion-buttons slot="start">
                                <ion-button id="clearFilterButton">${t('reset')}</ion-button>
                            </ion-buttons>
                            <ion-title class="filter">${t('Filter')}</ion-title>
                            <ion-buttons slot="end">
                                <ion-button id="closeModalButton">${t('done')}</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content class="ion-padding">
                        <div class="filter-group">
                            <div class="filter-grid">
                                    <div
                                        style="background-image: url('/icons/gastgarten-${hasGastgarten}.svg')"
                                        id="filter-garden"
                                        class="filter-grid__card garden ${hasGastgarten}"
                                    >
                                        ${t('gastgarten')}
                                    </div>
                                    <div
                                        style="background-image: url('/icons/mittag-${hasMittagsmenu}.svg')"
                                        id="filter-lunch"
                                        class="filter-grid__card garden ${hasMittagsmenu}"
                                    >
                                        ${t('mittagsmenu')}
                                    </div>
                                    <div
                                        style="background-image: url('/icons/vegetarisch-${hasVegetarian}.svg')"
                                        id="filter-vegetarian"
                                        class="filter-grid__card garden ${hasVegetarian}"
                                    >
                                        ${t('vegetarian')}
                                    </div>
                                    <div
                                        style="background-image: url('/icons/vegan-${hasVegan}.svg')"
                                        id="filter-vegan"
                                        class="filter-grid__card garden ${hasVegan}"
                                    >
                                        ${t('vegan')}
                                    </div>
                                    <div
                                        style="background-image: url('/icons/glutenfrei-${hasGluten}.svg')"
                                        id="filter-gluten"
                                        class="filter-grid__card garden ${hasGluten}"
                                    >
                                        ${t('glutenfree')}
                                    </div>
                                    <div
                                        style="background-image: url('/icons/schnelles_essen-${hasSchnell}.svg')"
                                        id="filter-fast"
                                        class="filter-grid__card garden ${hasSchnell}"
                                    >
                                        ${t('schnell')}
                                    </div>
                                    <div
                                        style="background-image: url('/icons/parkplatz-${hasParkplatz}.svg')"
                                        id="filter-parking"
                                        class="filter-grid__card garden ${hasParkplatz}"
                                    >
                                        ${t('parking')}
                                    </div>
                                    <div
                                        style="background-image: url('/icons/kids-${hasSpielplatz}.svg')"
                                        id="filter-spielplatz"
                                        class="filter-grid__card garden ${hasSpielplatz}"
                                    >
                                        ${t('playground')}
                                    </div>
                            </div>
                        </div>
                    </ion-content>
                `,
            },
            cssClass: 'filter-modal sg-modal',
            breakpoints: [0, 1],
            initialBreakpoint: modalRatio,
        });
        filterModalRef.current = modal; // Store the modal instance
        await modal.present();

        attachFilterEventListeners();
    
        // Attach the close functionality to the close button
        const closeButton = document.getElementById('closeModalButton');
        if (closeButton) {
            closeButton.addEventListener('click', () => modal.dismiss());
        }

        const resetButton = document.getElementById('clearFilterButton');
        if (resetButton) {
            resetButton.addEventListener('click', () => clearFilterClick());
        }
    };

    const clearFilterClick = () => {
        setHasGastgarten(false);
        setHasMittagsmenu(false);
        setHasParkplatz(false);
        setHasSchnell(false);
        setHasVegatarian(false);
        setHasVegan(false);
        setHasGluten(false);
        setHasSpieplatz(false);

        clearFilter();
        updateFilterModalContent();
    }

    const updateFilterModalContent = () => {
        const modalContent = document.querySelector('.filter-grid');
        console.log('update modal content');
        if (modalContent) {
            modalContent.innerHTML = `
                            <div
                                style="background-image: url('/icons/gastgarten-${hasGastgarten}.svg')"
                                id="filter-garden"
                                class="filter-grid__card garden ${hasGastgarten}"
                            >
                                ${t('gastgarten')}
                            </div>
                            <div
                                style="background-image: url('/icons/mittag-${hasMittagsmenu}.svg')"
                                id="filter-lunch"
                                class="filter-grid__card garden ${hasMittagsmenu}"
                            >
                                ${t('mittagsmenu')}
                            </div>
                            <div
                                style="background-image: url('/icons/vegetarisch-${hasVegetarian}.svg')"
                                id="filter-vegetarian"
                                class="filter-grid__card garden ${hasVegetarian}"
                            >
                                ${t('vegetarian')}
                            </div>
                            <div
                                style="background-image: url('/icons/vegan-${hasVegan}.svg')"
                                id="filter-vegan"
                                class="filter-grid__card garden ${hasVegan}"
                            >
                                ${t('vegan')}
                            </div>
                            <div
                                style="background-image: url('/icons/glutenfrei-${hasGluten}.svg')"
                                id="filter-gluten"
                                class="filter-grid__card garden ${hasGluten}"
                            >
                                ${t('glutenfree')}
                            </div>
                            <div
                                style="background-image: url('/icons/schnelles_essen-${hasSchnell}.svg')"
                                id="filter-fast"
                                class="filter-grid__card garden ${hasSchnell}"
                            >
                                ${t('schnell')}
                            </div>
                            <div
                                style="background-image: url('/icons/parkplatz-${hasParkplatz}.svg')"
                                id="filter-parking"
                                class="filter-grid__card garden ${hasParkplatz}"
                            >
                                ${t('parking')}
                            </div>
                            <div
                                style="background-image: url('/icons/kids-${hasSpielplatz}.svg')"
                                id="filter-playground"
                                class="filter-grid__card garden ${hasSpielplatz}"
                            >
                                ${t('playground')}
                            </div>
            `;
            attachFilterEventListeners();
        }
    };
    
    const attachFilterEventListeners = () => {
        const gardenFilter = document.getElementById('filter-garden');
        const lunchFilter = document.getElementById('filter-lunch');
        const parkingFilter = document.getElementById('filter-parking');
        const fastFilter = document.getElementById('filter-fast');
        const vegetarianFilter = document.getElementById('filter-vegetarian');
        const veganFilter = document.getElementById('filter-vegan');
        const glutenFilter = document.getElementById('filter-gluten');
        const playgroundFilter = document.getElementById('filter-playground');
    
        if (gardenFilter) {
            gardenFilter.addEventListener('click', () => {
                setHasGastgarten((hasGastgarten) => !hasGastgarten); 
                updateFilterModalContent();
                changeFilter(9);
            });
        }
    
        if (lunchFilter) {
            lunchFilter.addEventListener('click', () => {
                setHasMittagsmenu((hasMittagsmenu) => !hasMittagsmenu);
                updateFilterModalContent();
                changeFilter(22);
            });
        }
    
        if (parkingFilter) {
            parkingFilter.addEventListener('click', () => {
                setHasParkplatz((hasParkplatz) => !hasParkplatz);
                updateFilterModalContent();
                changeFilter(129);
            });
        }
    
        if (fastFilter) {
            fastFilter.addEventListener('click', () => {
                setHasSchnell((hasSchnell) => !hasSchnell);
                updateFilterModalContent();
                changeFilter(138);
            });
        }

        if (vegetarianFilter) {
            vegetarianFilter.addEventListener('click', () => {
                setHasVegatarian((hasVegetarian) => !hasVegetarian);
                updateFilterModalContent();
                changeFilter(50);
            });
        }
        if (veganFilter) {
            veganFilter.addEventListener('click', () => {
                setHasVegan((hasVegan) => !hasVegan);
                updateFilterModalContent();
                changeFilter(102);
            });
        }
        if (glutenFilter) {
            glutenFilter.addEventListener('click', () => {
                setHasGluten((hasGluten) => !hasGluten);
                updateFilterModalContent();
                changeFilter(131);
            });
        }
        if (playgroundFilter) {
            playgroundFilter.addEventListener('click', () => {
                setHasSpieplatz((hasSpielplatz) => !hasSpielplatz);
                updateFilterModalContent();
                changeFilter(135);
            });
        }
    };

    useEffect(() => {
        if (filterModalRef.current) {
            updateFilterModalContent();
        }
    }, [hasGastgarten, hasMittagsmenu, hasParkplatz, hasSchnell, hasVegetarian, hasVegan, hasGluten, hasSpielplatz]);

    const changeFilter = (filterToToggle: number) => {
        let filterArray: number[] = [...filterCategories];
        debugger

        if (filterArray.includes(filterToToggle)) {
            const index = filterArray.indexOf(filterToToggle);
            if (index > -1) { // only splice array when item is found
                filterArray.splice(index, 1); // 2nd parameter means remove one item only
            }
        } else {
            filterArray.push(filterToToggle);
        }

        // @ts-ignore
        setFilterCategories(filterArray);
        let tempVenues = filterVenues(venues, filterArray);
        setFilteredVenues(tempVenues);

        // @ts-ignore
        getOpenVenues(tempVenues, datetimeValue, datetimeFilterOn, sortingPreference);
    }
 
    const clearFilter = () => {
        let filterArray: number[] = [];

        // @ts-ignore
        setFilterCategories(filterArray);
        let tempVenues = filterVenues(venues, filterArray);
        setFilteredVenues(tempVenues);

        // @ts-ignore
        getOpenVenues(tempVenues, datetimeValue, datetimeFilterOn, sortingPreference);
    }

    //DateTime Popover

    const datetimePopoverRef = useRef<HTMLIonPopoverElement | null>(null);

    const openDatetimePopover = async () => {
        const now = new Date();
        const roundedDate = roundToNearest15Minutes(now);
        const minDate = roundedDate.toISOString();

        const popover = await popoverController.create({
            component: 'div',
            mode: 'ios',
            componentProps: {
                innerHTML: `
                    <ion-datetime
                        class="sg-datetime"
                        name="dateTime"
                        id="customDatetime"
                        show-default-buttons="true"
                        prefer-wheel="true"
                        done-text=${t('activate')}
                        cancel-text=${datetimeFilterOn ? t('disable') : t('cancel')}
                        locale=${language+'-DE'}
                        first-day-of-week="1"
                        hour-values="0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23"
                        minute-values="0, 15, 30, 45"
                        mode="ios"
                    ></ion-datetime>
                `,
            },
            cssClass: 'datetime-popover',
        });
    
        datetimePopoverRef.current = popover;
        await popover.present();
    
        // Handle the submission and cancellation of the datetime selection
        const ionDatetime = document.getElementById('customDatetime') as HTMLIonDatetimeElement | null;
        if (ionDatetime) {
            ionDatetime.addEventListener('ionChange', (event) => {
                const dateValue = event.detail.value;
                console.log(dateValue);
    
                if (dateValue) {
                    console.log('timefilter set with '+dateValue);
                    const selectedDate = new Date(Array.isArray(dateValue) ? dateValue[0] : dateValue);
    
                    setDatetimeValue(selectedDate);
                    setDatetimeFilterOn(true);
                    // @ts-ignore
                    getOpenVenues(filteredVenuesRef.current, selectedDate, true, sortingPreference);

                } else {
                    console.error('Invalid date value:', dateValue);
                }
    
                popover.dismiss();
            });

            ionDatetime.addEventListener('ionCancel', () => {
                console.log('timefilter cancel');
                const dtValue = new Date();
                setDatetimeValue(dtValue);
                setDatetimeFilterOn(false);
                // @ts-ignore
                getOpenVenues(filteredVenuesRef.current, dtValue, false, sortingPreference);
        
                popover.dismiss();
            });
        }
    };

    const roundToNearest15Minutes = (date: Date): Date => {
        const minutes = 15;
        const ms = 1000 * 60 * minutes; // 15 minutes in milliseconds
        return new Date(Math.ceil(date.getTime() / ms) * ms);
    };
      
    // Close modals on component dismiss

    useEffect(() => {
        return () => {
            if (descriptionModalRef.current) {
                descriptionModalRef.current.dismiss().then(() => {
                    descriptionModalRef.current = null;
                });
            }
            if (filterModalRef.current) {
                filterModalRef.current.dismiss().then(() => {
                    filterModalRef.current = null;
                });
            }
            if (sortingModalRef.current) {
                sortingModalRef.current.dismiss().then(() => {
                    sortingModalRef.current = null; // Referenz bereinigen
                });
            }
            if (datetimePopoverRef.current) {
                datetimePopoverRef.current.dismiss().then(() => {
                    datetimePopoverRef.current = null;
                });
            }
        };
    }, []);
    

    //map marker
    const [selectedMarkerID, setSelectedMarkerID] = useState<number>(-1);

    const handleMarkerSelected = (index: number | null) => {
        if (index !== null) {
            setSelectedMarkerID(index);
            console.log(index);
        }
    };

    useEffect(() => {
        const storedAds = localStorage.getItem('ads');
        if(storedAds) {
            setAds(JSON.parse(storedAds)['ads']);
            setAdsFrequency(JSON.parse(storedAds)['ad_frequency']);
        }

        if (!categoriesLoaded || !venuesLoaded) return;
        trackPageView({
            href: window.location.href, // URL der aktuellen Seite
          });

        // Get Categories
        const categories: any = localStorage.getItem('categories');
        if (categories) {
            console.log(categoryName);
            let categorySlug = categoryName;

            if (categorySlug === "alle") {
                categorySlug = parentCategoryName;
            }

            let currentCategoryId = -1;
            // @ts-ignore
            JSON.parse(categories).forEach((item: any) => {
                if (item.slug === categorySlug) {
                    console.log('Item:', item);
                    setCategory(item);
                    console.log('Category after set:', category);

                    currentCategoryId = item.id;
                    // @ts-ignore
                    setFilterCategories([currentCategoryId]);
                }
            })

            if (currentCategoryId < 0) {
                navigate('/notfound');
            }


            // Get Venues
            // @ts-ignore
            let tempVenues: any = JSON.parse(localStorage.getItem('venues'));
            tempVenues = filterVenues(tempVenues, [currentCategoryId]);
            setVenues(tempVenues);
            tempVenues = filterVenues(tempVenues, filterCategories);
            setFilteredVenues(tempVenues);


            console.log(category)

            // @ts-ignore
            getOpenVenues(tempVenues, new Date(), datetimeFilterOn, sortingPreference);

            console.log(venuesOpen);
            console.log(venuesClosed);

        }
    }, [location, categoriesLoaded, venuesLoaded]);


    function getOpenVenues(venues: [], date: Date, timeFilterOn: boolean, sortingPref: string) {
        let newVenues = venues.map((item: any) => ({
            ...item
        }));

        let openVenues: any[] = [];
        let closedVenues: any[] = [];


        let dow = date.getDay();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const floatTime = hours + minutes / 60
        //Sonntag auf europäischen Wert
        if (dow === 0) {
            dow = 7;
        }
        //Feiertagcheck
        const holidayCheck = isHoliday(date);
        if (holidayCheck.isHoliday) {
            dow = 8;

            const holidayDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
            const existingHoliday = Cookies.get('HolidayInfo');
    
            // Prüfen, ob das Cookie existiert und denselben Wert hat
            if (existingHoliday !== holidayDate) {
                Cookies.set('HolidayInfo', holidayDate, { expires: 1, secure: true, sameSite: 'Strict' });
                showHolidayToast();
            }
        }


        for (let i = 0; i < newVenues.length; i++) {

            // Checks if the venues is open in the selected time & dow and returns 0 if venue is closed on the day or -[open time] if the venue opens on the day or + [open till time] if venue is open
            let openValue = getVenueOpeningHours(newVenues[i].restaurant__opening_hours, floatTime, dow);

            newVenues[i].isOpen = false;
            if (openValue > 0) {
                // TODO: schließt demnächst check einbauen
                newVenues[i].status = t('todayUntil') + openValueToTimeString(openValue);
                newVenues[i].isOpen = true;
                openVenues.push(newVenues[i]);
            } else if (openValue < 0) {
                // TODO: Zuastz if Bedignung einfügen wenn nur gewisse Stunden vorher angezeigt
                // e.g. && (openValue - time) < 4
                newVenues[i].status = t('closed') + t('opensAt') + openValueToTimeString((-openValue));
                if (timeFilterOn) {
                    closedVenues.push(newVenues[i]);
                } else {
                    openVenues.push(newVenues[i]);
                }
            } else {
                newVenues[i].status = t('closed');
                if (timeFilterOn) {
                    closedVenues.push(newVenues[i]);
                } else {
                    openVenues.push(newVenues[i]);
                }
            }
        }

        // @ts-ignore
        setVenuesOpen(openVenues);
        // @ts-ignore
        setVenuesClosed(closedVenues);
        // @ts-ignore
        performSorting(openVenues, closedVenues, sortingPref);


        const newmarkers = newVenues.map(({ id, title, isOpen, restaurant__gps_lat, restaurant__gps_lon }) => ({
            id,
            title,
            isOpen,
            restaurant__gps_lat,
            restaurant__gps_lon,
        }));

        newmarkers.sort((a, b) => b.restaurant__gps_lat - a.restaurant__gps_lat);
        // @ts-ignore
        setVenuesMarker(newmarkers);

    }

    ////// --- Sorting Functions ----- ///////

    const changeSortingPreference = (sortingpref: string) => {
        setSortingPreference(sortingpref);
        // @ts-ignore
        performSorting(venuesOpen, venuesClosed, sortingpref);
    }

    const performSorting = (venues_open: [], venues_closed: [], sortingpref: string) => {
        if (sortingpref === 'distance') {
            getUserPosition()
                .then(pos => {
                    console.log("User Position: ", pos);
                    // @ts-ignore
                    sortVenues(venues_open, venues_closed, 'distance', pos.lat, pos.lon)
                })
                .catch(error => {
                    alert(error);
                    localStorage.setItem('sorting', 'popularity');
                    setSortingPreference('popularity');
                });
        } else {
            sortVenues(venues_open, venues_closed, 'popularity', 0, 0)
        }
    }

    const getUserPosition = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lon: position.coords.longitude
                        };
                        resolve(pos);
                    },
                    () => {
                        reject("Leider haben wir aktuell keinen Zugriff auf deinen Standort. Bitte überprüfe deine Einstellungen!");
                    }
                );
            } else {
                reject("Leider haben wir aktuell keinen Zugriff auf deinen Standort. Bitte überprüfe deine Einstellungen!");
            }
        });
    }

    function sortVenues(venues_open: [], venues_closed: [], sorting_pref: string, user_lat: number, user_lon: number) {

        if (sorting_pref === 'popularity') {
            console.log('sort popularity')
            if (venues_open.length) {
                console.log('sort venues open')
                let newOpenVenues = venues_open.map((item: any) => ({
                    ...item
                }));

                newOpenVenues.sort((a, b) => b.restaurant__views - a.restaurant__views);

                // @ts-ignore
                setVenuesOpen(newOpenVenues);
            }
            if (venues_closed.length) {
                console.log('sort venues closed')
                let newClosedVenues = venues_closed.map((item: any) => ({
                    ...item
                }));

                newClosedVenues.sort((a, b) => b.restaurant__views - a.restaurant__views);

                // @ts-ignore
                setVenuesClosed(newClosedVenues);
            }
        }

        if (sorting_pref === 'distance') {
            console.log('sort distance')
            if (venues_open.length) {
                let newOpenVenues = venues_open.map((item: any) => ({
                    ...item
                }));

                let distance = 0;
                let distance_text = '';

                for (let i = 0; i < newOpenVenues.length; i++) {

                    distance = getDistance(parseFloat(newOpenVenues[i].restaurant__gps_lat), parseFloat(newOpenVenues[i].restaurant__gps_lon), user_lat, user_lon)
                    newOpenVenues[i].distance = distance;
                    if (distance > 999) {

                        let first = Math.floor(distance / 1000);
                        let remainder = parseInt(String(distance % 1000)[0]);
                        distance_text = (first + ',' + remainder + ' km');
                    } else {
                        distance_text = distance + ' m';
                    }
                    newOpenVenues[i].distance_text = distance_text;
                }

                newOpenVenues.sort((b, a) => b.distance - a.distance);

                // @ts-ignore
                setVenuesOpen(newOpenVenues);
            }
            if (venues_closed.length) {
                let newClosedVenues = venues_closed.map((item: any) => ({
                    ...item
                }));

                let distance = 0;
                let distance_text = '';

                for (let i = 0; i < newClosedVenues.length; i++) {

                    distance = getDistance(parseFloat(newClosedVenues[i].restaurant__gps_lat), parseFloat(newClosedVenues[i].restaurant__gps_lon), user_lat, user_lon)
                    newClosedVenues[i].distance = distance;
                    if (distance > 999) {

                        let first = Math.floor(distance / 1000);
                        let remainder = parseInt(String(distance % 1000)[0]);
                        distance_text = (first + ',' + remainder + ' km');
                    } else {
                        distance_text = distance + ' m';
                    }
                    newClosedVenues[i].distance_text = distance_text;
                }

                newClosedVenues.sort((b, a) => b.distance - a.distance);

                // @ts-ignore
                setVenuesClosed(newClosedVenues);

            }
        }
    }

    const countFiltersSet = () => {
        const arr = [hasGastgarten, hasMittagsmenu, hasParkplatz, hasSchnell, hasVegetarian, hasVegan, hasGluten, hasSpielplatz];
        const count = arr.filter(Boolean).length;
        return count;
    }

    ////// Return Statement ///////   

    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
            categoriesLoaded && venuesLoaded ? (
                <ion-page class="sg-body">
                    <div className="page-container">
                    <MetaHelmet 
                        title={(language === 'de' ? category?.name_de : category?.name_en) + ' in Graz'}
                        desc= {language === 'de' ? category?.description_de : category?.description_en}
                    />
                    <ion-header class="ion-padding cat-header" id="header-category" mode="ios">
                        <div className="header-container top-margin">
                        {internalOrigin && <button className="arrow-back" style={{ backgroundImage: `url("/icons/back_arrow_white.svg")` }} onClick={() => navigate(-1)}></button>}
                            <div className="header-container__title">
                                <h1 className="start-title left">
                                    {language === 'de' ? category?.name_de : category?.name_en}
                                </h1>
                                <span className="share-icon info" onClick={openDescriptionModal}></span>
                            </div>
                            <span onClick={() => shareUrl()} className="share-icon"></span>
                        </div>
                        <div className="filter-map-container">
                            <ion-segment class="segment" id="segmentListMap" value={currentSlide}>
                                <ion-segment-button class="segment-button" value={0}>
                                    <label>{t('list')}</label>
                                </ion-segment-button>
                                <ion-segment-button class="segment-button" value={1}>
                                    <label>{t('map')}</label>
                                </ion-segment-button>
                            </ion-segment>
                            <span className="sg-filter" onClick={openFilterModal}>
                                {countFiltersSet() > 0 && <span>{countFiltersSet()}</span>}
                            </span>
                        </div>
                    </ion-header>
                    < div className="date-time-circle">
                        {
                            datetimeFilterOn ? (
                                <div className="date-spans active" onClick={openDatetimePopover}>
                                    <span className="time">{formatDateToTimeString(datetimeValue)}</span>
                                    <span className="date">{formatDateToDateString(datetimeValue)}</span>
                                </div>
                            ) : (
                                <div className="date-spans" onClick={openDatetimePopover}>
                                    <span className="time">{formatDateToTimeString(datetimeValue)}</span>
                                    <span className="date">{formatDateToDateString(datetimeValue)}</span>
                                </div>
                            )
                        }
                    </div >
                    <div className="slider-container">
                    <Slider ref={sliderRef} {...sliderSettings}>
                        <div className="slide">
                                <span onClick={openSortingModal} className="sortedBy">
                                    {t('sortedBy')}
                                    <strong>{t(sortingPreference)}</strong>
                                    <span className="chevronDown" />
                                </span>
                                <div className="venue-list">
                                    {venuesOpen.map((item, index) => (
                                        // (index > 5 && index % adsFrequency === adsFrequency - 1) && ads && ads[index % ads.length]['ad_field-status'] ? (
                                        // <>
                                        //     <AdCard
                                        //         text={ads[index % ads.length]['ad_field-text']}
                                        //         imageUrl={ads[index % ads.length]['ad_field-image']['url']}
                                        //     />
                                        //     <VenueCard
                                        //         key={item['id']}
                                        //         slug={item['slug']}
                                        //         name={item['title']}
                                        //         status={item['status'] + (sortingPreference === 'distance' ? ' | ' + item['distance_text'] : '')}
                                        //         additionalInformation=""
                                        //         cssClass={item['isOpen'] ? 'open' : 'closed'}
                                        //     />
                                        // </> ) : (
                                        <VenueCard
                                            key={item['id']}
                                            slug={item['slug']}
                                            name={item['title']}
                                            status={item['status'] + (sortingPreference === 'distance' && item['distance_text'] ? ' | ' + item['distance_text'] : '')}
                                            additionalInformation=""
                                            cssClass={item['isOpen'] ? 'open' : 'closed'}
                                            eventLink=""
                                            menuLink={category?.slug === 'menu' ? item['restaurant__mittagsteller'] : ""}
                                        />
                                        )
                                    // )
                                    )}
                                    {venuesClosed.map((item, index) => (
                                    // (index > 5 && index % adsFrequency === adsFrequency - 1) && ads && ads[index % ads.length]['ad_field-status'] ? (
                                    //     <>
                                    //         <AdCard
                                    //             text={ads[index % ads.length]['ad_field-text']}
                                    //             imageUrl={ads[index % ads.length]['ad_field-image']['url']}
                                    //         />
                                    //         <VenueCard
                                    //             key={item['id']}
                                    //             slug={item['slug']}
                                    //             name={item['title']}
                                    //             status={item['status'] + (sortingPreference === 'distance' ? ' | ' + item['distance_text'] : '')}
                                    //             additionalInformation=""
                                    //             cssClass={item['isOpen'] ? 'open' : 'closed'}
                                    //         />
                                    //     </> ) : (
                                        <VenueCard
                                            key={item['id']}
                                            slug={item['slug']}
                                            name={item['title']}
                                            status={item['status'] + (sortingPreference === 'distance' && item['distance_text'] ? ' | ' + item['distance_text'] : '')}
                                            additionalInformation=""
                                            cssClass={item['isOpen'] ? 'open' : 'closed'}
                                            eventLink=""
                                            menuLink={category?.slug === 'menu' ? item['restaurant__mittagsteller'] : ""}
                                        />
                                        )
                                    // )
                                    )}
                                </div>
                            </div>
                        <div className="slide">
                                <CategoryMap markers={venuesMarker} onMarkerSelected={handleMarkerSelected}></CategoryMap>
                                {selectedMarkerID !== -1 && (
                                    <Link state={{ fromInternal: true }} className="venue-list__item map" to={`/betriebe/${getVenueById(selectedMarkerID, venues)?.slug}`}>
                                        <h3 className="venue-list__item-name">{getVenueById(selectedMarkerID, venues)?.title}</h3>
                                        <p className="venue-list__item-hours">{getVenueById(selectedMarkerID, venuesOpen)?.status}</p>
                                        <p className="venue-list__item-hours">{getVenueById(selectedMarkerID, venuesClosed)?.status}</p>
                                    </Link>
                                )}
                        </div>
                    </Slider>
                    </div>
                    </div>
                </ion-page >
            ) : (
                <ion-page class="sg-body">
                <LoadingScreen />
              </ion-page>
            )
        )
    );
}

export default pageTransition(Category);