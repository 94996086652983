import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

interface MetaHelmetProps {
  title?: string;
  desc?: string;
  img_url?: string;
}

const MetaHelmet: React.FC<MetaHelmetProps> = ({
  title = 'smartGastro',
  desc = 'Finde über 1000 Gastro-Betriebe in Graz! Restaurants, Bars, Cafés sowie Events, entdecke versteckte Schätze und exklusive Berichte auf smartGastro.',
  img_url = 'https://smartgastro.at/images/sG_fallback_small.jpeg',
}) => {
  const location = useLocation();
  const fullLinkUrl = `${window.location.origin}${location.pathname}`;

  const fullTitle = title ? `${title} | smartGastro` : 'smartGastro | Dein Gastroguide in Graz';

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="title" content={fullTitle} />
      <meta name="description" content={desc} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={fullLinkUrl} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={img_url} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={fullLinkUrl} />
      <meta property="twitter:title" content={fullTitle} />
      <meta property="twitter:description" content={desc} />
      <meta property="twitter:image" content={img_url} />
    </Helmet>
  );
};

export default MetaHelmet;
