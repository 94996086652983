import React, { useRef, useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import "@maptiler/geocoding-control/style.css";
import 'maplibre-gl/dist/maplibre-gl.css';
import { t } from 'i18next';

interface MapComponentProps {
  markers: MarkerImport[];
  onMarkerSelected: (selectedMarkerID: number | null) => void;
}

type MarkerImport = {
  id: number;
  title?: string;
  isOpen: boolean;
  restaurant__gps_lat: number;
  restaurant__gps_lon: number;
};

const CategoryMap: React.FC<MapComponentProps> = ({ markers, onMarkerSelected }) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<maplibregl.Map | null>(null);
  const graz = { lng: 15.4395, lat: 47.0707 };
  const [zoom] = useState(12);
  const [language] = useState(typeof window !== 'undefined' ? localStorage.getItem('i18nextLng') || 'de' : 'de');

  const categoryMarkers = useRef<MarkerImport[]>(markers);
  const markersOnMap = useRef<maplibregl.Marker[]>([]);
  const oldSelectedMarkerIndex = useRef(-1);
  const selectedMarkerIndex = useRef(-1);
  const markerSelectionRef = useRef(false);
  const [mapController, setMapController] = useState<any>(null);
  const [GeocodingControl, setGeocodingControl] = useState<any>(null);

  useEffect(() => {
    if (map.current) return;

    map.current = new maplibregl.Map({
      container: mapContainer.current!,
      style: `https://api.maptiler.com/maps/basic/style.json?key=KHWONvKvBhvZQOL37lv8`,
      center: [graz.lng, graz.lat],
      zoom: zoom,
      attributionControl: false,
    });

    map.current.on('click', handleMapClick);

    setTimeout(() => {
      map.current?.resize();
      const geolocateControl = new maplibregl.GeolocateControl({
        positionOptions: { enableHighAccuracy: true },
        trackUserLocation: false,
        showAccuracyCircle: true,
      });
      map.current?.addControl(geolocateControl, 'bottom-right');
    }, 500);

    addMarkers();

    // client side import as this are not working with SSR
    import("@maptiler/geocoding-control/maplibregl-controller").then((module) => {
      setMapController(module.createMapLibreGlMapController(map.current!, maplibregl));
    });
    import("@maptiler/geocoding-control/react").then((module) => {
      setGeocodingControl(() => module.GeocodingControl);
    });
  }, [graz.lng, graz.lat, zoom]);

  useEffect(() => {
    categoryMarkers.current = markers;
    selectedMarkerIndex.current = -1;
    resetMarkers();
  }, [markers]);

  const handleMapClick = () => {
    if (!markerSelectionRef.current) {
      selectedMarkerIndex.current = -1;
    }

    if (selectedMarkerIndex.current !== oldSelectedMarkerIndex.current) {
      resetMarkers();
      oldSelectedMarkerIndex.current = selectedMarkerIndex.current;

      if (selectedMarkerIndex.current >= 0) {
        onMarkerSelected(categoryMarkers.current[selectedMarkerIndex.current].id);
      } else {
        onMarkerSelected(-1);
      }
    }
    markerSelectionRef.current = false;
  };

  const resetMarkers = () => {
    if (map.current) {
      markersOnMap.current.forEach((marker) => marker.remove());
      markersOnMap.current = [];
      addMarkers();
    }
  };

  const addMarkers = () => {
    if (!map.current) return;

    const newMarkers: maplibregl.Marker[] = [];
    for (let i = 0; i < categoryMarkers.current.length; i++) {
      const color = selectedMarkerIndex.current === i ? '#F1F1F1' : categoryMarkers.current[i].isOpen ? '#698047' : '#808080';

      const newMarker = new maplibregl.Marker({ color, draggable: false })
        .setLngLat([categoryMarkers.current[i].restaurant__gps_lon, categoryMarkers.current[i].restaurant__gps_lat])
        .addTo(map.current!);

      newMarker.getElement()?.addEventListener('click', markerClick(i));
      if (selectedMarkerIndex.current === i) {
        newMarker.getElement()?.classList.add('selected');
      }
      newMarkers.push(newMarker);
    }
    markersOnMap.current = newMarkers;
  };

  const markerClick = (index: number) => () => {
    selectedMarkerIndex.current = index;
    markerSelectionRef.current = true;
  };

  return (
    <div className="map-wrap">
      <div className="map-geocoding">
        {GeocodingControl && (
          <GeocodingControl
            apiKey={'KHWONvKvBhvZQOL37lv8'}
            selectFirst={false}
            mapController={mapController}
            bbox={[15.35, 47.00, 15.55, 47.15]}
            limit={5}
            language={language}
            placeholder={t('placesearch')}
            noResultsMessage={t('noplaceresult')}
          />
        )}
      </div>
      <div ref={mapContainer} className="map" />
    </div>
  );
};

export default CategoryMap;