import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import StartSettings from "../components/startSettings";
//React Slick Imports
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pageTransition from "../components/pageTransition";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";
import LoadingScreen from "../components/loadingScreen";


interface GalleryProps {
    venuesLoaded: boolean;
}

interface Venue {
    id: number;
    title: string;
    slug: string;
    category: number[];
    parent_category: number[];
    restaurant__gallery: string[];
    restaurant__closed: {
        from: string;
        until: string;
    };
    restaurant__mittagsteller: string;
    restaurant__website: string;
    restaurant__menu: string;
    restaurant__gps_lon: string;
    restaurant__gps_lat: string;
    restaurant__name: string;
    restaurant__opening_hours: {
        "restaurant__opening_hours-open": string;
        "restaurant__opening_hours-close": string;
        "restaurant__opening_hours-dow_till": string;
        "restaurant__opening_hours-warm_cuisine": boolean;
        "restaurant__opening_hours-dow_from": string;
    }[];
    restaurant__email: string;
    restaurant__phone_number: string;
    restaurant__address: string;
    restaurant__google_key: string;
    restaurant__views: string;
    google_key: string;
}



const Gallery: React.FC<GalleryProps> = ({ venuesLoaded }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Use `useLocation` to extract venue
    const location = useLocation();
    const internalOrigin = location.state?.fromInternal || false;
    const pathname = location.pathname.endsWith('/')
    ? location.pathname.slice(0, -1)
    : location.pathname;
    const segments = pathname.substring(1).split('/'); 
    const venueSlug = segments.length > 1 ? segments[segments.length - 2] : null;
    const venuePath = segments.slice(0, -1).join('/');

    const { trackPageView } = useMatomo();

    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));

    const [venue, setVenue] = useState<Venue | null>(null);
    const [gallery, setGallery] = useState<string[]>([]);

    const gallerySliderSettings = {
        dots: true,           // Dots anzeigen
        infinite: true,       // Keine Endlosschleife
        slidesToShow: 1,       // Nur eine Slide anzeigen
        slidesToScroll: 1,
        autoplay: false,       // Kein automatisches Weiterblättern
        arrows: true,           // Pfeile für die Navigation (optional)
        swipe: true,
    };


    ////// Helper Functions /////// 


    function getVenueBySlug(slug: string, venues: Venue[]): Venue | null {
        const foundVenue = venues.find((venue) => venue.slug === slug);
        return foundVenue || null;
    }


    ////// Page useEffect ///////   
    useEffect(() => {
        if (!venuesLoaded) return;
        trackPageView({
            href: window.location.href, // URL der aktuellen Seite
          });

        let tempVenues = localStorage.getItem('venues');
        if (tempVenues) {
            let venues = JSON.parse(tempVenues);
            const currentVenue = getVenueBySlug(venueSlug || '', venues);

            if (currentVenue) {
                setVenue(currentVenue);
                setGallery(currentVenue.restaurant__gallery);
            }
            else {
                navigate('/notfound');
            }
        }
    }, [location, venuesLoaded]);


    ////// Return Statement ///////   
    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
            venuesLoaded && venue ? (
                <ion-page class="sg-body gallery">
                <div className="page-container">
                <MetaHelmet 
                    title={venue.title + ' - Graz'}
                    desc={`Alle Details zu ${venue.title} in Graz findest du auf smartGastro. Entdecke Öffnungszeiten, Speisekarten und mehr!`}
                />
                    <ion-header mode="ios">
                        <div className="header-container gallery venue ion-padding">
                            {internalOrigin && <button className="arrow-back" style={{ backgroundImage: `url("/icons/back_arrow_white.svg")` }} onClick={() => navigate(-1)}></button>}
                            {!internalOrigin && <button className="arrow-back" style={{ backgroundImage: `url("/icons/back_arrow_white.svg")` }} onClick={() => navigate('/betriebe/'+venueSlug)}></button>}
                            <h1 className="start-title left">{venue.title}</h1>
                        </div>
                    </ion-header>
                            <div className="gallery-container">
                                {gallery.length > 0 && (
                                    <Slider {...gallerySliderSettings}>
                                        {gallery.map((src) => {
                                            return (
                                                <div className="slide">
                                                    <img src={src} alt='' />
                                                </div>
                                            );
                                        })}

                                    </Slider>
                                )}
                                {gallery.length < 1 && (
                                    <>
                                        <div className="venue-no-fotos">
                                            Leider fehlen uns noch Bilder für diesen Betrieb. Mit einem Klick auf "Fotos zusenden" kannst du dem Gastronomen bei der Außenwahrnehmung unterstützen.
                                            <a
                                                className="sg-button"
                                                href={`mailto:bilder@smartgastro.at?subject=Bilder ${venue.title}&amp;body=Liebes SmartGastro Team, %0D%0Ahiermit sende ich euch im Anhang Bilder vom Betrieb ${venue.title}.%0D%0AIch bestätige, dass ich für diese Bilder das Urheberrecht besitze und euch die Rechte zur Nutzung der Bilder zur Verfügung stelle.`}
                                            >
                                                FOTOS ZUSENDEN
                                            </a>
                                        </div>
                                    </>
                                )}
                            </div>
                            </div>
                </ion-page>
            ) : (
                <ion-page class="sg-body">
                    <LoadingScreen />
              </ion-page>
            )
        )
    );
}

export default pageTransition(Gallery);
