import React, { useState, useEffect } from "react";
import pageTransition from "../components/pageTransition";
import CategoryCard from "../components/categoryCard";
import StartSettings from "../components/startSettings";
import SearchBar from "../components/searchBar";
import { useTranslation } from "react-i18next";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useLocation, useNavigate } from 'react-router-dom';
import MetaHelmet from '../components/metaHelmet';

const Home: React.FC = () => {
    const { t } = useTranslation();
    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));

    const location = useLocation();
    const internalOrigin = location.state?.fromInternal || false;
    const { trackPageView } = useMatomo();

    const navigate = useNavigate();

    const showNewToast = async () => {
        // Create the toast element
        const toast = document.createElement('ion-toast');
        toast.message = 'Willkommen bei smartGastro 2.0. Kennst du schon unsere neuen Funktionen?';
        toast.duration = 10000; // Show for 10
        toast.position = 'bottom'; // Or 'bottom', 'middle'
          // Add buttons to the toast
        toast.mode ='ios';
        toast.swipeGesture='vertical';
        toast.buttons = [
            {
            text: 'Mehr dazu',
            role: 'info',
            handler: () => {
                navigate(`/aktuelles/smartgastro-vol-2-updates`, { state: { fromInternal: true } });
            },
            },
        ];
        toast.cssClass = 'info-toast';
    
        // Append the toast to the document body
        document.body.appendChild(toast);
    
        // Present the toast
        await toast.present();
    };

      // Function to parse custom date format with seconds
  const parseCustomDate = (dateString: string): Date => {
    const [day, month, yearAndTime] = dateString.split('.');
    const [year, time] = yearAndTime.split(', ');
    const [hours, minutes, seconds] = time.split(':');

    return new Date(
      parseInt(year),        // Year
      parseInt(month) - 1,   // Month (0-indexed in JavaScript Date)
      parseInt(day),         // Day
      parseInt(hours),       // Hours
      parseInt(minutes),     // Minutes
      parseInt(seconds)      // Seconds
    );
  };

  const checkConsentAndShowToast = () => {
    const i18nextLang = localStorage.getItem('i18nextLng');
    const consentGiven = localStorage.getItem('consent-given');
  
    // Combine the checks for language and consent time
    if (i18nextLang === 'de' && consentGiven) {
      const consentTime = parseCustomDate(consentGiven);
      const currentTime = new Date().getTime();
      const timeDifference = (currentTime - consentTime.getTime()) / 1000; // time in seconds
  
      // Show toast if the consent was given less than 4 seconds ago
      if (timeDifference < 4) {
        showNewToast();
      }
    }
  };
  
    useEffect(() => {
      trackPageView({
        href: window.location.href, // URL der aktuellen Seite
      });

      checkConsentAndShowToast();
    }, [location]);

    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
            <ion-page class="sg-body home">
                <div className="page-container">
                <MetaHelmet 
                    title="" 
                />
                <div
                    className="logo-container"
                    style={{ backgroundImage: `url("/icons/smartGastro-rund-white.svg")` }}
                >
                    <h1
                        className="start-title">{t('start_title')}</h1>
                </div>
                <div className="searchbar-container">
                    <SearchBar initialtext='' />
                </div>
                <div className="grid-container home">
                    <CategoryCard currentUrl={""} name={t('essen')} slug="essen" isParent={true} />
                    <CategoryCard currentUrl={""} name={t('trinken')} slug="trinken" isParent={true} />
                    <CategoryCard currentUrl={""} name={t('aktivitaeten')} slug="aktivitaeten" isParent={true} />
                    <CategoryCard currentUrl={""} name={t('events')} slug="events" isParent={true} />
                </div>
                </div>
            </ion-page>
        )
    );
};

export default pageTransition(Home);
