import React, { useEffect, useState } from "react";
import pageTransition from "../components/pageTransition";
import { useLocation, useNavigate } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";

const Impressum: React.FC = () => {

    // Load settings
    const [language] = useState(localStorage.getItem('i18nextLng') || 'en');

    const location = useLocation();
    const internalOrigin = location.state?.fromInternal || false;
    const { trackPageView } = useMatomo();

    const navigate = useNavigate();
  
    useEffect(() => {
      trackPageView({
        href: window.location.href, // URL der aktuellen Seite
      });
    }, [location]);

    return (
        <ion-page class="sg-body generic">
            <div className="page-container">
            <MetaHelmet 
                title="Impressum"
            />
            <img className="post-image" src={'/images/sG_fallback_small.jpeg'} />
            <div className="header-container ion-padding news">
                {internalOrigin &&<button className="arrow-back" style={{ backgroundImage: `url("/icons/back_arrow_white.svg")` }} onClick={() => navigate(-1)}></button>}
                <h1 className="start-title">
                    Impressum
                </h1>
            </div>
            {(language === 'de') &&
                <div className="generic-page german">
                    <b>smartBite OG</b>
                    Offene Gesellschaft<br />
                    <br />
                    FN: 646017a<br />
                    FB-Gericht: Graz<br />
                    <br />
                    <b>Sitz</b>
                    Mariagrünerstraße 33<br />
                    8043 Graz, Österreich<br />
                    <br />
                    <br />
                    <b>Kontaktdaten</b>
                    Tel: <a href="tel:+436764647177">+436764647177</a>
                    E-Mail: <a href="mailto:info@smartgastro.at">info@smartgastro.at</a>
                    <br />
                    Sollten Sie Fragen zum Datenschutz haben, finden unter folgendem Link unsere <a href="/datenschutz">Datenschutzerklärung</a>
                </div>
            }
            {(language !== 'de') &&
                <div className="generic-page english">
                    <b>smartBite OG</b>
                    Offene Gesellschaft<br />
                    <br />
                    FN: 646017a<br />
                    FB-Gericht: Graz<br />
                    <br />
                    <b>Office</b>
                    Mariagrünerstraße 33<br />
                    8043 Graz, Österreich<br />
                    <br />
                    <br />
                    <b>Contact</b>
                    Tel: <a href="tel:+436764647177">+436764647177</a><br />
                    Email: <a href="mailto:info@smartgastro.at">info@smartgastro.at</a><br />
                    <br />
                    If you have any questions about data protection, you can find our <a href="/datenschutz">privacy policy</a> at the following link.
                </div>
            }
            </div>
        </ion-page>
    );
}

export default pageTransition(Impressum);
