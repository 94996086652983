import { Link } from 'react-router-dom';

export default function PostCard(props: { post: any }) {
    const { post } = props;
    return (
        <Link state={{ fromInternal: true }} className="post-card" to={`/aktuelles/${post.slug}`}>
            <img src={post.featured_media} />
            <span className="post-date">{post.publish_date}</span>
            <span className="post-title">{post.title}</span>
        </Link>
    )
}