import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import pageTransition from "../components/pageTransition";
import { useLocation, useNavigate } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";

const AboutUs: React.FC = () => {
    const { t } = useTranslation();

    // Load settings    
    const [language] = useState(localStorage.getItem('i18nextLng') || 'en');

    const location = useLocation();
    const internalOrigin = location.state?.fromInternal || false;

    const { trackPageView } = useMatomo();

    const navigate = useNavigate();
  
    useEffect(() => {
      trackPageView({
        href: window.location.href, // URL der aktuellen Seite
      });
    }, [location]);

    return (
        <ion-page class="sg-body generic">
            <div className="page-container">
                <MetaHelmet 
                    title={t('about-us')} 
                />
                <img className="post-image" src={'/images/sG_crew_small.jpeg'} />
                <div className="header-container ion-padding news">
                    {internalOrigin && <button className="arrow-back" style={{ backgroundImage: `url("/icons/back_arrow_white.svg")` }} onClick={() => navigate(-1)}></button>}
                    <h1 className="start-title">
                        {t('about-us')}
                    </h1>
                </div>
            {(language === 'de') &&
                <div className="generic-page german">
                    <p>Wir, smartGastro, sind vier Freunde mit dem Ziel, den Gastro-Besuch in Graz noch attraktiver zu bewerben. Wir leben alle in unserer wunderschönen Stadt und wollen unbedingt jedem Betrieb eine Bühne bieten. Eines unserer Ziele ist es, Betriebe in den Vordergrund zu rücken, die derzeit noch unter dem Radar fliegen.</p>
                    <p>Von unserer Seite gibt es die Garantie, dass die normale Nutzung für unsere User immer kostenfrei sein wird. Alle Betriebe sind bei uns automatisch eingetragen und kein Betrieb zahlt für diese Leistung.</p>
                    <p>Für unsere Website haben wir viele spannende Erweiterungen angedacht, jedoch wollen wir nicht aus den Augen verlieren, dass sich die Seite auf unsere Leidenschaft, die Gastronomie, konzentrieren wird.</p>
                    <p>Obwohl wir schon viele Betriebe bei uns gelistet haben, streben wir eine möglichst vollständige Erfassung an. Wir hoffen hierbei auf zahlreiche Unterstützungen unserer Nutzer und auch der Gastronomen. Ihr könnt uns unter <a href="mailto:bilder@smartgastro.at">bilder@smartgastro.at</a> Fotos zu euren Lieblingsbetrieben schicken oder uns unter <a href="mailto:info@smartgastro.at">info@smartgastro.at</a> auf Fehler oder Ungenauigkeiten aufmerksam machen.</p>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            }
            {(language !== 'de') &&
                <div className="generic-page english">
                    <p>We, smartGastro, are four friends with the goal of making dining experiences in Graz even more attractive. We all live in our beautiful city and are eager to give every business a platform. One of our goals is to bring attention to establishments that are currently flying under the radar.</p>
                    <p>We guarantee that normal use of our site will always be free for our users. All businesses are automatically listed with us, and no business pays for this service.</p>
                    <p>We have many exciting expansions planned for our website, but we don’t want to lose sight of our focus on our passion: gastronomy.</p>
                    <p>Although we already have many businesses listed, we aim for the most comprehensive coverage possible. We hope to receive support from both our users and the restaurant owners. You can send us photos of your favorite establishments at <a href="mailto:bilder@smartgastro.at">bilder@smartgastro.at</a> or notify us of errors or inaccuracies at <a href="mailto:info@smartgastro.at">info@smartgastro.at</a>.</p>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            }
            </div>
        </ion-page>
    );
}

export default pageTransition(AboutUs);

