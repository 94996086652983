import { Link } from 'react-router-dom';

export default function VenueCard(props: { name: string, slug: string, status: string, additionalInformation: string, cssClass: string, eventLink: string,  menuLink: string }) {
    const { name, slug, status, additionalInformation, cssClass, eventLink, menuLink} = props;

    return (
        <div className={`venue-list__item ${cssClass}`}>
            <Link state={{ fromInternal: true }} className="venue-list__item-link" to={`/betriebe/${slug}`}></Link>
            <h3 className="venue-list__item-name">
                {name}</h3>
            <p className="venue-list__item-hours">{status}</p>
            <p className="venue-list__item-hours">{additionalInformation}</p>
            {eventLink && eventLink !== "" && (
                <a
                    style={{ backgroundImage: `url("/icons/website-green.svg")` }}
                    className="event-link"
                    href={eventLink}
                ></a>
            )}
            {menuLink && menuLink.length > 0 && (
                <a
                    style={{ backgroundImage: `url("/icons/mittag-green.svg")` }}
                    className="event-link"
                    href={menuLink}
                ></a>
            )}
        </div>
    )
}